import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { TAsset, THistoryNameTab, THistoryTabs, TMarketTab } from '../../../types/types';
import {
	BottomNavigationIcons,
	CalendarIcon,
	DepositIcon,
	ExchangeIcon,
	HistoryNavIcon,
	WithdrawIcon,
} from '../icons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectAssetSymbolsDictionary,
	selectDepositWithdrawAssetsDictionary,
} from '../../../services/dictionaries';
import { useEffect, useState } from 'react';
import { CustomButton } from '../buttons';
import { selectLocale } from '../../../services/root';
import {
	setExchangeFromAmount,
	setExchangeRate,
	setExchangeSelectedFromAsset,
	setExchangeSelectedToAsset,
	setExchangeToAmount,
} from '../../../services/exchange/exchange.slice';
import { clearAmount, selectMarketAssets } from '../../../services/market';

const BottomNavigationContainer = () => {
	const location = useLocation();
	const navigate = useCustomNavigate();

	const mapIsActive = (iconName: string): boolean => {
		if (location?.pathname?.split('/')[2]?.toLowerCase() === iconName) {
			return true;
		}
		return false;
	};

	const handleNavigate = (route: string) => {
		navigate(route);
	};

	return (
		<div className="bottomNavigationContainer">
			<div className="bottomNavigationDivider" />
			<div className="innerBottomNavigationContainer">
				<div
					onClick={() => handleNavigate(ROUTES.MARKET.INDEX)}
					className="innerBottomNavigationItem"
				>
					<BottomNavigationIcons.BottomNavigationMarketIcon
						className={''}
						isActive={mapIsActive('market')}
					/>
					<h1
						className={
							'innerBottomNavigationText' +
							(mapIsActive('market')
								? ' innerBottomNavigationTextActive'
								: '')
						}
					>
						{t('assets_title')}
					</h1>
				</div>
				<div
					onClick={() => handleNavigate(ROUTES.EXCHANGE.INDEX)}
					className="innerBottomNavigationItem"
				>
					<BottomNavigationIcons.BottomNavigationExchangeIcon
						className={''}
						isActive={mapIsActive('exchange')}
					/>
					<h1
						className={
							'innerBottomNavigationText' +
							(mapIsActive('exchange')
								? ' innerBottomNavigationTextActive'
								: '')
						}
					>
						{t('wallet_exchange')}
					</h1>
				</div>
				<div
					onClick={() => handleNavigate(ROUTES.WALLET.INDEX)}
					className="innerBottomNavigationItem"
				>
					<BottomNavigationIcons.BottomNavigationWalletIcon
						className={''}
						isActive={mapIsActive('wallet')}
					/>
					<h1
						className={
							'innerBottomNavigationText' +
							(mapIsActive('wallet')
								? ' innerBottomNavigationTextActive'
								: '')
						}
					>
						{t('wallet_title')}
					</h1>
				</div>
			</div>
		</div>
	);
};

interface AssetDetailsNavigationContainerProps {
	code: string;
	mainRests: TAsset[] | undefined;
	spotRests: TAsset[] | undefined;
	isLoading: boolean;
}

const AssetDetailsNavigationContainer = ({
	code,
	mainRests,
	spotRests,
	isLoading,
}: AssetDetailsNavigationContainerProps) => {
	const depositWithdrawAssets = useAppSelector(selectDepositWithdrawAssetsDictionary);
	const assetSymbols = useAppSelector(selectAssetSymbolsDictionary);
	const marketAssets = useAppSelector(selectMarketAssets)?.all;

	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const loadingCards = ['1', '2', '3'];

	const [isAvailable, setIsAvailable] = useState<boolean>(false);

	useEffect(() => {
		if (mainRests && spotRests) {
			setIsAvailable(
				Number(mainRests?.find((asset) => asset.code === code)?.available) > 0 ||
					Number(spotRests?.find((asset) => asset.code === code)?.available) > 0
			);
		}
	}, [mainRests, spotRests]);

	const handleExchangeClick = () => {
		const tempMainAsset = mainRests?.find((asset) => asset.code === code)
			? {
					...mainRests?.find((asset) => asset.code === code),
			  }
			: undefined;
		const tempMarketAsset = {
			...marketAssets?.find((asset) => asset.code === code),
			available: tempMainAsset?.available ?? 0,
			baseAvailable: tempMainAsset?.baseAvailable ?? 0,
			baseReserved: tempMainAsset?.baseReserved ?? 0,
		};

		const copiedAssetWithRightPrecision = assetSymbols?.dictionary?.find(
			(symbol) =>
				symbol?.asset1?.code === tempMainAsset?.code ||
				symbol?.asset2?.code === tempMainAsset?.code
		);

		if (tempMainAsset) {
			tempMainAsset.precision =
				copiedAssetWithRightPrecision?.asset1?.code === tempMainAsset?.code
					? copiedAssetWithRightPrecision?.asset1?.precision
					: (copiedAssetWithRightPrecision?.asset2?.precision as number);
		}
		if (tempMarketAsset) {
			tempMarketAsset.precision =
				copiedAssetWithRightPrecision?.asset1?.code === tempMarketAsset?.code
					? copiedAssetWithRightPrecision?.asset1?.precision
					: (copiedAssetWithRightPrecision?.asset2?.precision as number);
		}

		dispatch(setExchangeRate(undefined));
		dispatch(setExchangeFromAmount(''));
		dispatch(setExchangeToAmount(''));

		dispatch(clearAmount());

		if (tempMainAsset) {
			dispatch(setExchangeSelectedFromAsset(tempMainAsset));
			navigate(ROUTES.EXCHANGE.INDEX);
			return;
		}

		if (tempMarketAsset) {
			dispatch(setExchangeSelectedToAsset(tempMarketAsset));
			navigate(ROUTES.EXCHANGE.INDEX);
			return;
		}
	};

	if (isLoading) {
		return (
			<div className="marketAssetDetailsNavigationContainerLoading">
				{loadingCards.map((value) => (
					<div
						className="marketAssetDetailsNavigationContainerItemLoading"
						key={value}
					/>
				))}
			</div>
		);
	}

	return (
		<div className="marketAssetDetailsNavigationContainer">
			{depositWithdrawAssets?.dictionary?.deposit?.all?.includes(code) && (
				<CustomButton
					isSmall
					className="marketAssetDetailsNavigationContainerItem"
					onClick={() => navigate(ROUTES.DEPOSIT.INDEX)}
					text={t('deposit_title') as string}
				/>
			)}
			<CustomButton
				isSmall
				className="marketAssetDetailsNavigationContainerItem"
				onClick={handleExchangeClick}
				text={t('exchange_title') as string}
			/>
			{depositWithdrawAssets?.dictionary?.withdraw?.all?.includes(code) &&
				isAvailable && (
					<CustomButton
						isSmall
						className="marketAssetDetailsNavigationContainerItem"
						onClick={() => navigate(ROUTES.WITHDRAW.INDEX)}
						text={t('withdraw_title') as string}
					/>
				)}
		</div>
	);
};

interface HistoryNavigationContainerProps {
	currentTab: string;
	handleTabChange: (tab: THistoryNameTab) => void;
	historyTabs: THistoryTabs;
	isLoading: boolean;
	handleTimeFrameClick: () => void;
	currentTimeFrame: string;
}

const HistoryNavigationContainer = ({
	currentTab,
	handleTabChange,
	historyTabs,
	isLoading,
	handleTimeFrameClick,
	currentTimeFrame,
}: HistoryNavigationContainerProps) => {
	const locale = useAppSelector(selectLocale);

	const mapIsActiveTab = (tab: THistoryNameTab) => {
		if (tab.toLowerCase() === currentTab?.toLowerCase()) {
			return true;
		}
		return false;
	};

	const handleTabClick = (event: React.MouseEvent, tab: THistoryNameTab) => {
		handleTabChange(tab);
	};

	return (
		<div className="historyNavigationContainer">
			{isLoading ? (
				<div className="scrolledAssetsNavigationLoading" />
			) : (
				<div className="scrolledAssetsNavigation">
					{historyTabs[locale as keyof THistoryTabs]?.map((tab, idx) => (
						<div
							key={idx}
							onClick={(event: React.MouseEvent) =>
								handleTabClick(event, tab)
							}
							className={
								'assetNavigationItem' +
								(mapIsActiveTab(tab) ? ' assetNavigationItemActive' : '')
							}
						>
							<h1 className="assetNavigationItemText">{tab}</h1>
						</div>
					))}
				</div>
			)}

			{isLoading ? (
				<div className="assetsDateFilterContainerLoading" />
			) : (
				<div
					className={
						'historyDateFilterContainer' +
						(currentTimeFrame != ''
							? ' historyDateFilterContainerFilled'
							: '')
					}
					onClick={handleTimeFrameClick}
				>
					<div
						className={
							'historyDateFilter' +
							(currentTimeFrame != '' ? ' historyDateFilterFilled' : '')
						}
					>
						<CalendarIcon />
						{currentTimeFrame != '' && (
							<h1 className="assetsDateMarginFilterText">
								{currentTimeFrame}
							</h1>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

interface AssetsNavigationContainerProps {
	currentTab: string;
	handleTabChange: (tab: TMarketTab) => void;
	marketTabs: TMarketTab[] | undefined;
	isLoading: boolean;
	handleTimeFrameClick?: () => void;
	currentTimeFrame?: string;
	isNoTimeFrame?: boolean;
}

const AssetsNavigationContainer = ({
	currentTab,
	handleTabChange,
	marketTabs,
	isLoading,
	handleTimeFrameClick,
	currentTimeFrame,
	isNoTimeFrame,
}: AssetsNavigationContainerProps) => {
	const mapIsActiveTab = (tab: TMarketTab) => {
		if (tab.name.toLowerCase() === currentTab.toLowerCase()) {
			return true;
		}
		return false;
	};

	const handleTabClick = (asset: TMarketTab) => {
		handleTabChange(asset);
	};

	return (
		<div
			style={
				isNoTimeFrame
					? {
							padding: 0,
					  }
					: {}
			}
			className="assetsNavigationContainer"
		>
			{isLoading ? (
				<div className="scrolledAssetsNavigationLoading" />
			) : (
				<div className="scrolledAssetsNavigation">
					{marketTabs?.map((asset, idx) => (
						<div
							key={idx}
							onClick={() => handleTabClick(asset)}
							className={
								'assetNavigationItem' +
								(mapIsActiveTab(asset)
									? ' assetNavigationItemActive'
									: '')
							}
						>
							<h1 className="assetNavigationItemText">{asset.name}</h1>
						</div>
					))}
				</div>
			)}

			{!isNoTimeFrame && (
				<>
					{isLoading ? (
						<div className="assetsDateFilterContainerLoading" />
					) : (
						<div
							className={'assetsDateFilterContainer'}
							onClick={handleTimeFrameClick}
						>
							<div className="assetsDateFilter">
								<CalendarIcon />
								<h1 className="assetsDateMarginFilterText">
									{currentTimeFrame}
								</h1>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

interface BaseProps {
	isLoading: boolean;
}

interface BaseNavigationProps extends BaseProps {
	isVerified: boolean;
	isEmpty: boolean;
}

const MainNavigationContainer = ({
	isVerified,
	isLoading,
	isEmpty,
}: BaseNavigationProps) => {
	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	return (
		<>
			{isVerified && (
				<>
					<div className="mainNavigationContainer">
						{isLoading ? (
							<>
								<div className="navigationItem">
									<div className="navIconLoading" />
								</div>
								<div className="navigationItem">
									<div className="navIconLoading" />
								</div>
								<div className="navigationItem">
									<div className="navIconLoading" />
								</div>
								<div className="navigationItem">
									<div className="navIconLoading" />
								</div>
							</>
						) : (
							<>
								{isEmpty ? (
									<div
										onClick={() => navigate(ROUTES.DEPOSIT.INDEX)}
										className="navigationItem"
									>
										<DepositIcon className="navigationIcon" />
										<h1 className="navigationText">
											{t('wallet_deposit')}
										</h1>
									</div>
								) : (
									<>
										<div
											onClick={() => navigate(ROUTES.DEPOSIT.INDEX)}
											className="navigationItem"
										>
											<DepositIcon className="navigationIcon" />
											<h1 className="navigationText">
												{t('wallet_deposit')}
											</h1>
										</div>
										<div
											onClick={() =>
												navigate(ROUTES.EXCHANGE.INDEX)
											}
											className="navigationItem"
										>
											<ExchangeIcon className="navigationIcon" />
											<h1 className="navigationText">
												{t('wallet_exchange')}
											</h1>
										</div>
										<div
											onClick={() =>
												navigate(ROUTES.WITHDRAW.INDEX)
											}
											className="navigationItem"
										>
											<WithdrawIcon className="navigationIcon" />
											<h1 className="navigationText">
												{t('wallet_withdraw')}
											</h1>
										</div>
										<div
											onClick={() => navigate(ROUTES.HISTORY.INDEX)}
											className="navigationItem"
										>
											<HistoryNavIcon className="navigationIcon" />
											<h1 className="navigationText">
												{t('history_title')}
											</h1>
										</div>
									</>
								)}
							</>
						)}
					</div>
				</>
			)}
		</>
	);
};

export {
	AssetsNavigationContainer,
	BottomNavigationContainer,
	MainNavigationContainer,
	AssetDetailsNavigationContainer,
	HistoryNavigationContainer,
};
