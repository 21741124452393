import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { useEffect } from 'react';
import {
	selectExchangeFromAsset,
	setExchangeInit,
} from '../../../services/exchange/exchange.slice';
import { ROUTES } from '../../../types/routes';
import { WhaleIcon } from '../../common/icons';
import { CustomButton } from '../../common/buttons';

const ExchangeSummaryLayout = () => {
	const selectedFromAsset = useAppSelector(selectExchangeFromAsset);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	useEffect(() => {
		if (selectedFromAsset === undefined) {
			dispatch(setExchangeInit());
			navigate(ROUTES.EXCHANGE.INDEX);
		}
	}, []);

	const handleBackClickButton = () => {
		dispatch(setExchangeInit());
		navigate(ROUTES.EXCHANGE.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div
					style={{
						zIndex: '1',
						top: 'auto',
						marginBottom: '140px',
						gap: '0',
					}}
					className="walletLayout authMainContainer"
				>
					<WhaleIcon className="whaleConvertIcon" />
					<h1 className={'modalWalletConvertSummaryHeader'}>
						{t('operation_operationIsProcessed')}
					</h1>
					<h1 className={'modalWalletConvertSummaryBottom'}>
						{t('operation_conversionProcessedDetails')}
					</h1>
				</div>
			</div>
			<div
				style={{
					zIndex: '3',
				}}
				className="depositButtonsBottomContainer"
			>
				<CustomButton
					isGrey
					text={t('general_finishAndClose') as string}
					onClick={handleBackClickButton}
					className="confirmFullWidthButton"
				/>
			</div>
		</div>
	);
};

export default ExchangeSummaryLayout;
