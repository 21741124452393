import { useEffect, useState } from 'react';
import { usePinInput } from 'react-pin-input-hook';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../../../types/routes';
import { PinInput } from '../../../../common/inputs';
import { CustomButton } from '../../../../common/buttons';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { selectAccessToken } from '../../../../../services/auth';
import { APIError } from '../../../../common/error';
import { mapClassnameForMobile } from '../../../../../utils';
import { HeaderLayout } from '../../../../common/header';
import {
	selectSecurityChangePassword,
	selectSecurityIdentityTypes,
	selectSecurityState,
} from '../../../../../services/security';
import { useSendSecurityPasswordChange } from '../../../../../data/mutations/profile';
import { selectIsTelegram } from '../../../../../services/root';

const SecurityChangePasswordTwoFactorLayout = () => {
	const accessToken = useAppSelector(selectAccessToken);
	const state = useAppSelector(selectSecurityState);
	const password = useAppSelector(selectSecurityChangePassword);
	const dispatch = useAppDispatch();
	const identityTypes = useAppSelector(selectSecurityIdentityTypes);
	const [fakeError, setFakeError] = useState<boolean>(false);

	const isTelegram = useAppSelector(selectIsTelegram);

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				sendSecurityPasswordChange({
					newPassword: password,
					twoFA: {
						code: values,
						state: state,
						identityType: 'GoogleAuthenticator',
					},
					navigate: navigate,
					dispatch: dispatch,
					accessToken: accessToken as string,
				});
			}
		},
		type: 'numeric',
		placeholder: '',
	});

	const navigate = useCustomNavigate();

	const { isError, error, sendSecurityPasswordChange, isPending } =
		useSendSecurityPasswordChange(navigate);

	const { t } = useTranslation();

	useEffect(() => {
		if (identityTypes.length === 0) {
			navigate(ROUTES.PROFILE.SECURITY.INDEX);
		}
	}, []);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	const handleBackClickButton = () => {
		navigate(ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.INDEX);
	};

	const handlePasteClick = async () => {
		const tempClipboardContent = await navigator.clipboard.readText();

		if (
			tempClipboardContent &&
			tempClipboardContent != '' &&
			tempClipboardContent.length === 6 &&
			!isNaN(Number(tempClipboardContent))
		) {
			setValues(tempClipboardContent.split(''));
			setFakeError(false);
			sendSecurityPasswordChange({
				newPassword: password,
				twoFA: {
					code: tempClipboardContent,
					state: state,
					identityType: 'GoogleAuthenticator',
				},
				navigate: navigate,
				dispatch: dispatch,
				accessToken: accessToken as string,
			});
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div className={mapClassnameForMobile('walletLayout', true)}>
					<div className="innerContainer">
						<h1 className={'confirmEmailText'}>
							{t('twoFactorAuthCode_title')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_pleaseEnter6DigitCode')}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={isPending}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						<APIError isError={fakeError} error={error} />
					</div>
				</div>
				{!isTelegram && (
					<div className="pasteButtonContainer">
						<CustomButton
							isGrey
							text={t('general_pasteCode') as string}
							onClick={handlePasteClick}
							className="confirmFullWidthButton"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default SecurityChangePasswordTwoFactorLayout;
