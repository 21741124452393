import {
	selectBankWithdrawAmount,
	selectBankWithdrawAsset,
	selectBankWithdrawBankCode,
	selectBankWithdrawBankCountry,
	selectBankWithdrawBankName,
	selectBankWithdrawCorrAccount,
	selectBankWithdrawCorrBankCode,
	selectBankWithdrawCorrBankName,
	selectBankWithdrawIbanAccountNumber,
	selectBankWithdrawIsBankCodeAvailable,
	selectBankWithdrawIsBankCountryAvailable,
	selectBankWithdrawIsBankNameAvailable,
	selectBankWithdrawIsBeneficiaryAccountAvailable,
	selectBankWithdrawIsChecked,
	selectBankWithdrawIsCorrAccountAvailable,
	selectBankWithdrawIsCorrBankCodeAvailable,
	selectBankWithdrawIsCorrBankNameAvailable,
	selectBankWithdrawIsPaymentSystemAvailable,
	selectBankWithdrawIsSelectAssetAvailable,
	selectBankWithdrawIsTaxIdentNumberAvailable,
	selectBankWithdrawParams,
	selectBankWithdrawPaymentSystem,
	selectBankWithdrawTaxIdentNumber,
	selectInputRequired,
	setBankWithdrawAmount,
	setBankWithdrawBankCode,
	setBankWithdrawBankCountry,
	setBankWithdrawBankName,
	setBankWithdrawCorrAccount,
	setBankWithdrawCorrBankCode,
	setBankWithdrawCorrBankName,
	setBankWithdrawIban,
	setBankWithdrawIsChecked,
	setBankWithdrawPaymentSystem,
	setBankWithdrawTaxIdentNumber,
	setIsInputRequired,
} from '../../../services/withdraw/bank.withdraw.slice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAssetIcons } from '../../../services/dictionaries';
import { CustomInput } from '../inputs';
import { CustomCheckbox } from '../checkboxes';
import { useTranslation } from 'react-i18next';
import { TAsset, TBankWithdrawInputKeys } from '../../../types/types';

interface Props {
    isWithdraw?: boolean;
	isCommon?: boolean;
	mainAsset?: TAsset;
	minValue?: number;
	handleModalOpen?: () => void;
	handleSavedAddressModalOpen?: () => void;
	handlePaymentSystemModalOpen?: () => void;
}

const BankWithdrawInputContainers = ({
    isWithdraw,
	isCommon,
	mainAsset,
	minValue,
	handleModalOpen,
	handlePaymentSystemModalOpen,
	handleSavedAddressModalOpen,
}: Props) => {
	const isInputRequired = useAppSelector(selectInputRequired);
	const selectedAsset = useAppSelector(selectBankWithdrawAsset);
	const ibanAccountNumber = useAppSelector(selectBankWithdrawIbanAccountNumber);
	const bankName = useAppSelector(selectBankWithdrawBankName);
	const taxIdentNumber = useAppSelector(selectBankWithdrawTaxIdentNumber);
	const corrAccount = useAppSelector(selectBankWithdrawCorrAccount);
	const corrBankCode = useAppSelector(selectBankWithdrawCorrBankCode);
	const corrBankName = useAppSelector(selectBankWithdrawCorrBankName);
	const paymentSystem = useAppSelector(selectBankWithdrawPaymentSystem);
	const bankCode = useAppSelector(selectBankWithdrawBankCode);
	const bankCountry = useAppSelector(selectBankWithdrawBankCountry);
	const bankParams = useAppSelector(selectBankWithdrawParams);
	const amount = useAppSelector(selectBankWithdrawAmount);
	const isChecked = useAppSelector(selectBankWithdrawIsChecked);

	const assetIcons = useAppSelector(selectAssetIcons);
	const selectAssetParam = useAppSelector(selectBankWithdrawIsSelectAssetAvailable);
	const selectBenificiaryAccountParam = useAppSelector(
		selectBankWithdrawIsBeneficiaryAccountAvailable
	);
	const selectBankCode = useAppSelector(selectBankWithdrawIsBankCodeAvailable);
	const selectBankName = useAppSelector(selectBankWithdrawIsBankNameAvailable);
	const selectPaymentSystem = useAppSelector(
		selectBankWithdrawIsPaymentSystemAvailable
	);
	const selectTaxIdentNumber = useAppSelector(
		selectBankWithdrawIsTaxIdentNumberAvailable
	);
	const selectCorrAccount = useAppSelector(selectBankWithdrawIsCorrAccountAvailable);
	const selectCorrBankCode = useAppSelector(selectBankWithdrawIsCorrBankCodeAvailable);
	const selectCorrBankName = useAppSelector(selectBankWithdrawIsCorrBankNameAvailable);
	const selectBankCountry = useAppSelector(selectBankWithdrawIsBankCountryAvailable);

	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const handleIsInputRequiredChange = (
		value: boolean,
		key: keyof TBankWithdrawInputKeys
	) => {
		dispatch(
			setIsInputRequired({
				key: key,
				value: value,
			})
		);
	};

	const handleSavedAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setBankWithdrawIban(e.target.value));
		handleIsInputRequiredChange(false, 'iban');
	};

	const handleSavedAddressClearClick = () => {
		dispatch(setBankWithdrawIban(''));
		handleIsInputRequiredChange(false, 'iban');
	};

	const handleBankCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setBankWithdrawBankCode(e.target.value));
		handleIsInputRequiredChange(false, 'bankCode');
	};

	const handleBankCodeClearClick = () => {
		dispatch(setBankWithdrawBankCode(''));
		handleIsInputRequiredChange(false, 'bankCode');
	};

	const handleBankNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setBankWithdrawBankName(e.target.value));
		handleIsInputRequiredChange(false, 'bankName');
	};

	const handleBankNameClearClick = () => {
		dispatch(setBankWithdrawBankName(''));
		handleIsInputRequiredChange(false, 'bankName');
	};

	const handleBankCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setBankWithdrawBankCountry(e.target.value));
		handleIsInputRequiredChange(false, 'bankCountry');
	};

	const handleBankCountryClearClick = () => {
		dispatch(setBankWithdrawBankCountry(''));
		handleIsInputRequiredChange(false, 'bankCountry');
	};

	const handlePaymentSystemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setBankWithdrawPaymentSystem(e.target.value));
	};

	const handlePaymentSystemClearClick = () => {
		dispatch(setBankWithdrawPaymentSystem(''));
	};

	const handleTaxIdentNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setBankWithdrawTaxIdentNumber(e.target.value));
		handleIsInputRequiredChange(false, 'taxIdentNumber');
	};

	const handleTaxIdentNumberClearClick = () => {
		dispatch(setBankWithdrawTaxIdentNumber(''));
		handleIsInputRequiredChange(false, 'taxIdentNumber');
	};

	const handleCorrAccountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setBankWithdrawCorrAccount(e.target.value));
		handleIsInputRequiredChange(false, 'corrAccount');
	};

	const handleCorrAccountClearClick = () => {
		dispatch(setBankWithdrawCorrAccount(''));
		handleIsInputRequiredChange(false, 'corrAccount');
	};

	const handleCorrBankNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setBankWithdrawCorrBankName(e.target.value));
		handleIsInputRequiredChange(false, 'corrBankName');
	};

	const handleCorrBankNameClearClick = () => {
		dispatch(setBankWithdrawCorrBankName(''));
		handleIsInputRequiredChange(false, 'corrBankName');
	};

	const handleCorrBankCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setBankWithdrawCorrBankCode(e.target.value));
		handleIsInputRequiredChange(false, 'corrBankCode');
	};

	const handleCorrBankCodeClearClick = () => {
		dispatch(setBankWithdrawCorrBankCode(''));
		handleIsInputRequiredChange(false, 'corrBankCode');
	};

	const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value?.length <= 12 + (mainAsset?.precision ?? 2)) {
			dispatch(setBankWithdrawAmount(e.target.value));
		}
	};

	const handleMaxClick = () => {
		if (
			selectedAsset != undefined &&
			mainAsset != undefined &&
			mainAsset?.available != undefined
		) {
			dispatch(
				setBankWithdrawAmount(mainAsset?.available ? mainAsset?.available : 0)
			);
		}
	};

	const handleClearClick = () => {
		dispatch(setBankWithdrawAmount(''));
	};

	const handleIsCheckedFeeChange = (eventValue: boolean) => {
		dispatch(setBankWithdrawIsChecked(eventValue));
	};

	return(
		<>
			{(isCommon || selectAssetParam?.isVisible || selectedAsset != undefined) && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isRequired={selectedAsset?.code != undefined}
						isDisabled={
							selectBenificiaryAccountParam?.valueList?.length === 1
						}
						isSelectAsset
						type="text"
						id="bankWithdrawSelectAsset"
						onClick={handleModalOpen}
						value={selectedAsset?.code as string}
						imgUrl={
							assetIcons?.dictionary &&
							assetIcons?.dictionary[
								selectedAsset?.code?.toUpperCase() as string
							]
						}
						className="confirmFullWidthButton"
						label={selectAssetParam?.paramName as string}
					/>
				</div>
			)}
			<div className="depositAmountInputContainer">
				<CustomInput
					isError={
						!!selectBenificiaryAccountParam?.errorMsg ||
						(selectBenificiaryAccountParam?.isRequired &&
							isInputRequired.iban)
					}
					isDisabled={selectBenificiaryAccountParam?.valueList?.length === 1}
					isNonCloseIcon={
						selectBenificiaryAccountParam?.valueList?.length === 1
					}
					isSavedAddress={!isCommon}
					onClick={handleSavedAddressModalOpen}
					label={selectBenificiaryAccountParam?.paramName as string}
					className="confirmFullWidthButton"
					type="text"
					id="bankWithdrawIBAN"
					onChange={handleSavedAddressChange}
					onClearClick={handleSavedAddressClearClick}
					isRequired={ibanAccountNumber?.length > 0}
					value={ibanAccountNumber}
				/>
				{isInputRequired.iban && (
					<h1 className={'loginErrorText'}>{t('general_mandatoryField')}</h1>
				)}
			</div>
			{selectBankCode?.isVisible && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isNonCloseIcon={selectBankCode?.valueList?.length === 1}
						isError={
							!!selectBankCode?.errorMsg ||
							(selectBankCode?.isRequired && isInputRequired.bankCode)
						}
						isDisabled={selectBankCode?.valueList?.length === 1}
						onChange={handleBankCodeChange}
						onClearClick={handleBankCodeClearClick}
						label={selectBankCode?.paramName as string}
						className="confirmFullWidthButton"
						type="text"
						id="bankWithdrawBankCode"
						isRequired={
							bankCode?.length > 0 ||
							(selectBankCode?.valueList
								? selectBankCode?.valueList[0]?.length > 0
								: false)
						}
						value={bankCode}
					/>
					{isInputRequired.bankCode && (
						<h1 className={'loginErrorText'}>
							{t('general_mandatoryField')}
						</h1>
					)}
				</div>
			)}
			{selectBankName?.isVisible && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isNonCloseIcon={selectBankName?.valueList?.length === 1}
						isError={
							!!selectBankName?.errorMsg ||
							(selectBankName?.isRequired && isInputRequired.bankName)
						}
						onChange={handleBankNameChange}
						onClearClick={handleBankNameClearClick}
						isDisabled={selectBankName?.valueList?.length === 1}
						label={selectBankName?.paramName as string}
						className="confirmFullWidthButton"
						type="text"
						id="bankWithdrawBankName"
						isRequired={
							bankName?.length > 0 ||
							(selectBankName?.valueList
								? selectBankName?.valueList[0]?.length > 0
								: false)
						}
						value={bankName}
					/>
					{isInputRequired.bankName && (
						<h1 className={'loginErrorText'}>
							{t('general_mandatoryField')}
						</h1>
					)}
				</div>
			)}
			{selectBankCountry?.isVisible && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isNonCloseIcon={selectBankCountry?.valueList?.length === 1}
						isError={
							!!selectBankCountry?.errorMsg || isInputRequired.bankCountry
						}
						onChange={handleBankCountryChange}
						onClearClick={handleBankCountryClearClick}
						isDisabled={selectBankCountry?.valueList?.length === 1}
						label={selectBankCountry?.paramName as string}
						className="confirmFullWidthButton"
						type="text"
						id="bankWithdrawBankCountry"
						isRequired={
							bankCountry?.length > 0 ||
							(selectBankCountry?.valueList
								? selectBankCountry?.valueList[0]?.length > 0
								: false)
						}
						value={bankCountry}
					/>
					{isInputRequired.bankCountry && (
						<h1 className={'loginErrorText'}>
							{t('general_mandatoryField')}
						</h1>
					)}
				</div>
			)}
			{selectPaymentSystem?.isVisible && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isError={!!selectPaymentSystem?.errorMsg}
						isDisabled={selectPaymentSystem?.valueList?.length === 1}
						isNonCloseIcon={selectPaymentSystem?.valueList?.length === 1}
						isSelectAsset={
							selectPaymentSystem?.valueList?.length > 1 ? true : false
						}
						onClick={handlePaymentSystemModalOpen}
						label={selectPaymentSystem?.paramName as string}
						onChange={handlePaymentSystemChange}
						onClearClick={handlePaymentSystemClearClick}
						className="confirmFullWidthButton"
						type="text"
						id="bankWithdrawPaymentSystem"
						isRequired={
							paymentSystem?.length > 0 ||
							(selectPaymentSystem?.valueList
								? selectPaymentSystem?.valueList[0]?.length > 0
								: false)
						}
						value={paymentSystem}
					/>
				</div>
			)}
			{selectTaxIdentNumber?.isVisible && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isError={
							!!selectTaxIdentNumber?.errorMsg ||
							(selectTaxIdentNumber?.isRequired &&
								isInputRequired.taxIdentNumber)
						}
						isDisabled={selectTaxIdentNumber?.valueList?.length === 1}
						label={selectTaxIdentNumber?.paramName as string}
						onChange={handleTaxIdentNumberChange}
						onClearClick={handleTaxIdentNumberClearClick}
						className="confirmFullWidthButton"
						type="text"
						id="bankWithdrawTaxIdentNumber"
						isRequired={
							taxIdentNumber?.length > 0 ||
							(selectTaxIdentNumber?.valueList
								? selectTaxIdentNumber?.valueList[0]?.length > 0
								: false)
						}
						value={taxIdentNumber}
					/>
					{isInputRequired.taxIdentNumber && (
						<h1 className={'loginErrorText'}>
							{t('general_mandatoryField')}
						</h1>
					)}
				</div>
			)}
			{selectCorrAccount?.isVisible && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isError={
							!!selectCorrAccount?.errorMsg ||
							(selectCorrAccount?.isRequired && isInputRequired.corrAccount)
						}
						isDisabled={selectCorrAccount?.valueList?.length === 1}
						label={selectCorrAccount?.paramName as string}
						onChange={handleCorrAccountChange}
						onClearClick={handleCorrAccountClearClick}
						className="confirmFullWidthButton"
						type="text"
						id="bankWithdrawCorrAccount"
						isRequired={
							corrAccount?.length > 0 ||
							(selectCorrAccount?.valueList
								? selectCorrAccount?.valueList[0]?.length > 0
								: false)
						}
						value={corrAccount}
					/>
					{isInputRequired.corrAccount && (
						<h1 className={'loginErrorText'}>
							{t('general_mandatoryField')}
						</h1>
					)}
				</div>
			)}
			{selectCorrBankCode?.isVisible && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isError={
							!!selectCorrBankCode?.errorMsg ||
							(selectCorrBankCode?.isRequired &&
								isInputRequired.corrBankCode)
						}
						isDisabled={selectCorrBankCode?.valueList?.length === 1}
						label={selectCorrBankCode?.paramName as string}
						onChange={handleCorrBankCodeChange}
						onClearClick={handleCorrBankCodeClearClick}
						className="confirmFullWidthButton"
						type="text"
						id="bankWithdrawCorrBankCode"
						isRequired={
							corrBankCode?.length > 0 ||
							(selectCorrBankCode?.valueList
								? selectCorrBankCode?.valueList[0]?.length > 0
								: false)
						}
						value={corrBankCode}
					/>
					{isInputRequired.corrBankCode && (
						<h1 className={'loginErrorText'}>
							{t('general_mandatoryField')}
						</h1>
					)}
				</div>
			)}
			{selectCorrBankName?.isVisible && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isError={
							!!selectCorrBankName?.errorMsg ||
							(selectCorrBankName?.isRequired &&
								isInputRequired.corrBankName)
						}
						isDisabled={selectCorrBankName?.valueList?.length === 1}
						label={selectCorrBankName?.paramName as string}
						onChange={handleCorrBankNameChange}
						onClearClick={handleCorrBankNameClearClick}
						className="confirmFullWidthButton"
						type="text"
						id="bankWithdrawCorrBankName"
						isRequired={
							corrBankName?.length > 0 ||
							(selectCorrBankName?.valueList
								? selectCorrBankName?.valueList[0]?.length > 0
								: false)
						}
						value={corrBankName}
					/>
					{isInputRequired.corrBankName && (
						<h1 className={'loginErrorText'}>
							{t('general_mandatoryField')}
						</h1>
					)}
				</div>
			)}

			{isWithdraw && bankParams?.isDisplayAmount && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isAmountMax
						label={
							selectedAsset != undefined
								? `${t('wallet_toBeReceived')} (${t(
										'withdraw_min'
								  )} ${minValue} ${selectedAsset?.code})`
								: t('wallet_toBeReceived')
						}
						className="confirmFullWidthButton"
						type="number"
						id="bankWithdrawAmount"
						onChange={handleAmountChange}
						onClick={handleMaxClick}
						onClearClick={handleClearClick}
						isRequired={amount?.length > 0}
						value={amount}
					/>
					{selectedAsset != undefined && (
						<h1 className="depositFeeText">{`${t(
							'general_availableAmount'
						)}: ${mainAsset?.available ? mainAsset?.available : 0.0} ${
							selectedAsset?.code
						}`}</h1>
					)}
					<div className="includeFeeContainer">
						<CustomCheckbox
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								handleIsCheckedFeeChange(e.target.checked)
							}
							key={selectedAsset?.code}
							checked={isChecked}
						/>
						<h1 className="includeFeeText">{t('withdraw_includeFee')}</h1>
					</div>
				</div>
			)}
		</>
	);
};

export { BankWithdrawInputContainers };
