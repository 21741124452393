import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import {
	selectSecurityIdentityTypes,
	selectSecurityState,
} from '../../../../../services/security';
import { useCustomNavigate } from '../../../../../hooks/useCustomNavigate';
import { useSendSecurityOTP } from '../../../../../data/mutations/profile';
import { ROUTES } from '../../../../../types/routes';
import { HeaderLayout } from '../../../../common/header';
import { mapClassnameForMobile } from '../../../../../utils';
import { TwoFAButton } from '../../../../common/buttons';
import { APIError } from '../../../../common/error';
import { selectAccessToken } from '../../../../../services/auth';

const SecurityChangePasswordSelectTwoFactorLayout = () => {
	const accessToken = useAppSelector(selectAccessToken);

	const dispatch = useAppDispatch();
	const state = useAppSelector(selectSecurityState);
	const identityTypes = useAppSelector(selectSecurityIdentityTypes);
	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { sendSecurityOTP, isError, error } = useSendSecurityOTP(navigate, false);

	const handleBackClick = () => {
		navigate(ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.INDEX);
	};

	const handleOTPSelect = (otpType: string) => {
		setIsModalOpen(!isModalOpen);
		if (otpType === 'GoogleAuthenticator') {
			navigate(ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.TWO_FA);
		} else {
			sendSecurityOTP({
				identityType: otpType,
				state: state as string,
				dispatch: dispatch,
				accessToken: accessToken as string,
			});
		}
	};

	const mapTextByIdentityValue = (identity: string) => {
		if (identity.toLowerCase() === 'email') {
			return t('twoFactorAuthMethods_email') as string;
		}
		if (identity.toLowerCase() === 'phone') {
			return t('twoFactorAuthMethods_phone') as string;
		}
		if (identity.toLowerCase() === 'googleauthenticator') {
			return t('twoFactorAuthMethods_app') as string;
		}
		return '';
	};

	useEffect(() => {
		if (identityTypes.length === 0) {
			navigate(ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.INDEX);
		}
	}, []);

	return (
		<>
			<div>
				<div className={'component'}>
					<div
						style={{
							backgroundColor: '#F6F6F8',
						}}
						className={'innerComponent'}
					>
						<HeaderLayout
							isFunctionalHeader
							isAboutHeader
							handleBackClick={handleBackClick}
						/>
						<div className={mapClassnameForMobile('walletLayout', true)}>
							<div className="innerContainer">
								<h1 className={'welcomeText'}>
									{t('twoFactorAuthMethods_title')}
								</h1>
								<h1 className={'confirmCodeText'}>
									{t('twoFactorAuthMethods_subtitle')}
								</h1>
								{identityTypes.map((identity) => (
									<TwoFAButton
										onClick={() => handleOTPSelect(identity.key)}
										text={mapTextByIdentityValue(identity.key)}
										iconType={identity.key}
									/>
								))}
								<APIError isError={isError} error={error} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SecurityChangePasswordSelectTwoFactorLayout;
