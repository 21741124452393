import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import { TUserActivity, TUserInfo } from '../../types/types';

export const selectIsVerified = (state: TRootState) => state.user.isVerified;

export const selectShortName = (state: TRootState) => state.user.shortName;

export const selectFullName = (state: TRootState) => state.user.fullName;

export const selectUserInfo = (state: TRootState) => state.user.userInfo;

export const selectUserEmail = (state: TRootState) => state.user.email;
export const selectUserPhone = (state: TRootState) => state.user.phone;

export const selectUserVerificationInfo = (state: TRootState) =>
	state.user.verificationInfo;

export const selectSumSubStatus = (state: TRootState) => state.user.statusSumSubId;

export const selectUserActivity = (state: TRootState) => state.user.activities;

export const selectUserActivityNextFrameId = (state: TRootState) =>
	state.user.nextFrameId;

interface IRootInitialState {
	isVerified: boolean;
	userInfo: TUserInfo[];
	shortName: string;
	fullName: string;
	verificationInfo: string;
	statusSumSubId: string;
	email: string;
	phone: string;
	activities: TUserActivity[];
	nextFrameId: string;
}

const initialState: IRootInitialState = {
	isVerified:
		JSON.parse(
			localStorage.getItem(localStorage.getItem('userId') as string) as string
		)?.isVerified != undefined
			? Boolean(
					JSON.parse(
						localStorage.getItem(
							localStorage.getItem('userId') as string
						) as string
					)?.isVerified
			  )
			: false,
	userInfo: [],
	verificationInfo: '',
	statusSumSubId: '',
	shortName: '',
	fullName: '',
	email: '',
	phone: '',
	activities: [],
	nextFrameId: '',
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setVerificationInfo(state, action) {
			state.verificationInfo = action.payload?.stepId;
			state.statusSumSubId = action.payload?.statusSumSubId;
		},
		setUserInfo(state, action) {
			state.userInfo = action.payload;
			const firstName = action.payload?.find(
				(param: TUserInfo) => param.paramId === 'firstName'
			)?.paramValue;
			const lastName = action.payload?.find(
				(param: TUserInfo) => param.paramId === 'lastName'
			)?.paramValue;
			state.email = action.payload?.find(
				(param: TUserInfo) => param.paramId === 'email'
			)?.paramValue;
			state.phone = action.payload?.find(
				(param: TUserInfo) => param.paramId === 'phone'
			)?.paramValue;

			state.fullName = firstName + ' ' + lastName;
			state.shortName = firstName?.substring(0, 1) + lastName?.substring(0, 1);
		},
		setIsVerified(state, action) {
			const accSettings = localStorage.getItem(
				localStorage.getItem('userId') as string
			);
			if (accSettings) {
				const parsedAccSettings = JSON.parse(accSettings);
				Object.assign(parsedAccSettings, {
					isVerified: action.payload,
				});
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify(parsedAccSettings)
				);
			} else {
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify({
						isVerified: action.payload,
					})
				);
			}
			state.isVerified = action.payload;
		},
		setUserActivities(state, action) {
			state.activities = action.payload.activities;
			state.nextFrameId = action.payload.nextFrameId;
		},
		setUserActivitiesInit(state) {
			state.activities = [];
			state.nextFrameId = '';
		},
	},
});

export const {
	setIsVerified,
	setUserInfo,
	setVerificationInfo,
	setUserActivities,
	setUserActivitiesInit,
} = userSlice.actions;
