import { createSlice } from '@reduxjs/toolkit';
import { TExchangeItem, TExchangeRate, TMarketTab } from '../../types/types';
import { TRootState } from '../../store';

export const selectExchangeFromAmount = (state: TRootState) => state.exchange.fromAmount;

export const selectExchangeToAmount = (state: TRootState) => state.exchange.toAmount;

export const selectExchangeToAsset = (state: TRootState) =>
	state.exchange.selectedToAsset;

export const selectExchangeFromAsset = (state: TRootState) =>
	state.exchange.selectedFromAsset;

export const selectExchangeRate = (state: TRootState) => state.exchange.exchangeRate;

export const selectExchangeRateText = (state: TRootState) =>
	state.exchange.exchangeRateText;

export const selectExchangeRateNum = (state: TRootState) =>
	state.exchange.exchangeRateNum;

export const selectExchangeStatus = (state: TRootState) => state.exchange.status;

export const selectExchangeCurrentTab = (state: TRootState) => state.exchange.currentTab;

interface IRootInitialState {
	fromAmount: string;
	toAmount: string;
	selectedToAsset: TExchangeItem | undefined;
	selectedFromAsset: TExchangeItem | undefined;
	exchangeRate: TExchangeRate | undefined;
	exchangeRateText: string;
	exchangeRateNum: number;
	status: boolean;
	currentTab: TMarketTab | undefined;
}

const initialState: IRootInitialState = {
	fromAmount: '',
	toAmount: '',
	selectedFromAsset: undefined,
	selectedToAsset: undefined,
	exchangeRate: undefined,
	exchangeRateText: '',
	exchangeRateNum: 0,
	status: false,
	currentTab: undefined,
};

export const exchangeSlice = createSlice({
	name: 'exchange',
	initialState,
	reducers: {
		setExchangeFromAmount(state, action) {
			state.fromAmount = action.payload;
		},
		setExchangeToAmount(state, action) {
			state.toAmount = action.payload;
		},
		setExchangeSelectedFromAsset(state, action) {
			state.selectedFromAsset = action.payload;
		},
		setExchangeSelectedToAsset(state, action) {
			state.selectedToAsset = action.payload;
		},
		setExchangeRate(state, action) {
			if (action.payload?.code === 'NOT_FOUND') {
				state.exchangeRate = undefined;
				state.exchangeRateText = action.payload?.msg;
				state.exchangeRateNum = 0;
				return;
			}
			if (action.payload === undefined) {
				state.exchangeRate = undefined;
				state.exchangeRateText = '';
				state.exchangeRateNum = 0;
				return;
			}
			if (
				state.selectedFromAsset?.code?.concat(
					state.selectedToAsset?.code as string
				) === action.payload?.symbol
			) {
				state.exchangeRateText = `1 ${
					state.selectedFromAsset?.code
				} ~ ${action.payload.priceBid.toFixed(
					action.payload?.assetRight?.precision
				)} ${state.selectedToAsset?.code}`;
				state.exchangeRateNum = action?.payload?.priceBid;
			} else {
				state.exchangeRateText = `1 ${
					state.selectedToAsset?.code
				} ~ ${action.payload.priceAsk.toFixed(
					action.payload?.assetRight?.precision
				)} ${state.selectedFromAsset?.code}`;
				state.exchangeRateNum = action?.payload?.priceAsk;
			}
			state.exchangeRate = action.payload;
		},
		setExchangeStatus(state, action) {
			state.status = action.payload;
		},
		setExchangeCurrentTab(state, action) {
			state.currentTab = action.payload;
		},
		setExchangeInit(state) {
			state.fromAmount = '';
			state.toAmount = '';
			state.selectedFromAsset = undefined;
			state.selectedToAsset = undefined;
			state.exchangeRate = undefined;
			state.exchangeRateText = '';
			state.exchangeRateNum = 0;
			state.status = false;
			state.currentTab = undefined;
		},
	},
});

export const {
	setExchangeFromAmount,
	setExchangeToAmount,
	setExchangeSelectedFromAsset,
	setExchangeSelectedToAsset,
	setExchangeRate,
	setExchangeInit,
	setExchangeStatus,
	setExchangeCurrentTab,
} = exchangeSlice.actions;
