import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { selectAccessToken } from '../../../../../services/auth';
import {
	reduceCounter,
	selectCounter,
	selectIsResendCodeActive,
	selectIsTelegram,
	setIsResendCodeActive,
	updateCounter,
} from '../../../../../services/root';
import {
	selectSecuritySelectedIdentityType,
	selectSecurityState,
	selectTwoFaOptionsCurrenMethodType,
} from '../../../../../services/security';
import { useCustomNavigate } from '../../../../../hooks/useCustomNavigate';
import { usePinInput } from 'react-pin-input-hook';
import { ROUTES } from '../../../../../types/routes';
import {
	useDisableTwoFaMutation,
	useEnableTwoFaMutation,
	useSendSecurityOTP,
} from '../../../../../data/mutations/profile';
import { useTranslation } from 'react-i18next';
import { HeaderLayout } from '../../../../common/header';
import { mapClassnameForMobile } from '../../../../../utils';
import { PinInput } from '../../../../common/inputs';
import { APIError } from '../../../../common/error';
import { CustomButton } from '../../../../common/buttons';
import { selectUserEmail, selectUserPhone } from '../../../../../services/user';

const SecurityTwoFaOTPLayout = () => {
	const isTelegram = useAppSelector(selectIsTelegram);
	const counter = useAppSelector(selectCounter);
	const isResendCodeActive = useAppSelector(selectIsResendCodeActive);
	const state = useAppSelector(selectSecurityState);
	const accessToken = useAppSelector(selectAccessToken);
	const selectedIdentity = useAppSelector(selectSecuritySelectedIdentityType);
	const currentMethodType = useAppSelector(selectTwoFaOptionsCurrenMethodType);

	const userEmail = useAppSelector(selectUserEmail);
	const userPhone = useAppSelector(selectUserPhone);

	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const [fakeError, setFakeError] = useState<boolean>(false);

	const mapTwoFaTypeToMethod = (values: string) => {
		if (currentMethodType === 'enable') {
			enableTwoFaMutation({
				accessToken: accessToken as string,
				dispatch: dispatch,
				identity: selectedIdentity === 'email' ? userEmail : userPhone,
				identityType: selectedIdentity,
				code: values,
				navigate: navigate,
			});
		} else if (currentMethodType === 'disable') {
			disableTwoFaMutation({
				accessToken: accessToken as string,
				dispatch: dispatch,
				navigate: navigate,
				twoFA: {
					state: state,
					code: values,
					identityType: selectedIdentity as string,
				},
				identityType: selectedIdentity as string,
			});
		}
	};

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				mapTwoFaTypeToMethod(values);
			}
		},
		type: 'numeric',
		placeholder: '',
	});

	useEffect(() => {
		if (selectedIdentity.length === 0) {
			navigate(ROUTES.PROFILE.SECURITY.TWO_FA.INDEX);
		}
	}, []);

	useEffect(() => {
		if (counter == 0) {
			dispatch(setIsResendCodeActive(true));
			dispatch(updateCounter(60));
		}
		const timer = counter > 0 && setInterval(() => dispatch(reduceCounter()), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	const { disableTwoFaMutation, disableError, isDisableError, isDisablePending } =
		useDisableTwoFaMutation(navigate);
	const { enableTwoFaMutation, enableError, isEnableError, isEnablePending } =
		useEnableTwoFaMutation(navigate);

	const { sendSecurityOTP } = useSendSecurityOTP(navigate, true);

	const { t } = useTranslation();

	useEffect(() => {
		if (isDisableError || isEnableError) {
			setFakeError(isDisableError || isEnableError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isDisableError, isEnableError]);

	const handleBackClickButton = () => {
		navigate(ROUTES.PROFILE.SECURITY.TWO_FA.INDEX);
	};

	const handleResendCode = () => {
		sendSecurityOTP({
			identityType: selectedIdentity,
			state: state,
			dispatch: dispatch,
			accessToken: accessToken as string,
		});
	};

	const handlePasteClick = async () => {
		const clipboardContent = await navigator.clipboard.readText();
		if (
			clipboardContent &&
			clipboardContent.length === 6 &&
			!isNaN(Number(clipboardContent))
		) {
			setValues(clipboardContent.split(''));
			setFakeError(false);

			mapTwoFaTypeToMethod(clipboardContent);
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div className={mapClassnameForMobile('walletLayout', true)}>
					<div className="innerContainer">
						<h1 className={'confirmEmailText'}>
							{t(
								selectedIdentity.toLocaleLowerCase() === 'email'
									? 'twoFactorAuthMethods_email'
									: 'twoFactorAuthMethods_phone'
							)}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_subtitle') +
								' ' +
								(selectedIdentity.toLowerCase() === 'email'
									? t('login_email')
									: t('registration_phone'))}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={isDisablePending || isEnablePending}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						{isResendCodeActive ? (
							<h1 className={'resendCodeText'} onClick={handleResendCode}>
								{t('general_resendCode')}
							</h1>
						) : (
							<h1 className={'resendCodeText-inactive'}>
								{t('general_resendCodeIn')} {counter}
							</h1>
						)}
						<APIError
							isError={fakeError}
							error={disableError || enableError}
						/>
					</div>
				</div>
				{!isTelegram && (
					<div className="pasteButtonContainer">
						<CustomButton
							isGrey
							text={t('general_pasteCode') as string}
							onClick={handlePasteClick}
							className="confirmFullWidthButton"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default SecurityTwoFaOTPLayout;
