import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
	reduceCounter,
	selectCounter,
	selectIsResendCodeActive,
	selectIsTelegram,
	setIsResendCodeActive,
	updateCounter,
} from '../../../../services/root';
import {
	selectWithdrawIdentityTypes,
	selectWithdrawSelectedIdentityTypes,
	selectWithdrawState,
	selectWithdrawType,
	setWithdrawInit,
} from '../../../../services/withdraw/withdraw.slice';
import { usePinInput } from 'react-pin-input-hook';
import {
	useSendBankWithdraw,
	useSendCardWithdraw,
	useSendCryptoWithdraw,
	useSendWithdrawOTP,
} from '../../../../data/mutations/withdraw';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../../types/routes';
import { useTranslation } from 'react-i18next';
import { selectAccessToken } from '../../../../services/auth';
import {
	selectBankWithdrawAmount,
	selectBankWithdrawAsset,
	selectBankWithdrawBankCode,
	selectBankWithdrawBankName,
	selectBankWithdrawCorrAccount,
	selectBankWithdrawIbanAccountNumber,
	selectBankWithdrawIsBankCountryAvailable,
	selectBankWithdrawParams,
	selectBankWithdrawPaymentSystem,
	selectBankWithdrawTaxIdentNumber,
} from '../../../../services/withdraw/bank.withdraw.slice';
import { selectMainEWallet } from '../../../../services/wallet';
import {
	selectCryptoWithdrawAddress,
	selectCryptoWithdrawAmount,
	selectCryptoWithdrawAsset,
	selectCryptoWithdrawNetwork,
} from '../../../../services/withdraw/crypto.withdraw.slice';
import {
	selectCardWithdrawAmount,
	selectCardWithdrawAsset,
	selectCardWithdrawCard,
} from '../../../../services/withdraw/card.withdraw.slice';
import { TBankWithdrawBodyParam, TBankWithdrawParamIds } from '../../../../types/types';
import { HeaderLayout } from '../../../common/header';
import { mapClassnameForMobile } from '../../../../utils';
import { PinInput } from '../../../common/inputs';
import { APIError } from '../../../common/error';
import { CustomButton } from '../../../common/buttons';

const WithdrawOTPLayout = () => {
	const isTelegram = useAppSelector(selectIsTelegram);
	const selectedIdentityType = useAppSelector(selectWithdrawSelectedIdentityTypes);
	const counter = useAppSelector(selectCounter);
	const identityTypes = useAppSelector(selectWithdrawIdentityTypes);
	const isResendCodeActive = useAppSelector(selectIsResendCodeActive);
	const state = useAppSelector(selectWithdrawState);
	const accessToken = useAppSelector(selectAccessToken);
	const withdrawType = useAppSelector(selectWithdrawType);
	const selectedIdentity = useAppSelector(selectWithdrawSelectedIdentityTypes);

	const amount = useAppSelector(selectBankWithdrawAmount);
	const selectedAsset = useAppSelector(selectBankWithdrawAsset);
	const mainEWallet = useAppSelector(selectMainEWallet);
	const bankParams = useAppSelector(selectBankWithdrawParams);

	const ibanAccountNumber = useAppSelector(selectBankWithdrawIbanAccountNumber);
	const bankName = useAppSelector(selectBankWithdrawBankName);
	const taxIdentNumber = useAppSelector(selectBankWithdrawTaxIdentNumber);
	const corrAccount = useAppSelector(selectBankWithdrawCorrAccount);
	const paymentSystem = useAppSelector(selectBankWithdrawPaymentSystem);
	const bankCode = useAppSelector(selectBankWithdrawBankCode);

	const address = useAppSelector(selectCryptoWithdrawAddress);
	const cryptoAmount = useAppSelector(selectCryptoWithdrawAmount);
	const cryptoSelectedAsset = useAppSelector(selectCryptoWithdrawAsset);
	const selectedNetwork = useAppSelector(selectCryptoWithdrawNetwork);
	const accountNumber = useAppSelector(selectMainEWallet);

	const cardAmount = useAppSelector(selectCardWithdrawAmount);
	const cardSelectedAsset = useAppSelector(selectCardWithdrawAsset);
	const savedCard = useAppSelector(selectCardWithdrawCard);

	const selectBankCountry = useAppSelector(selectBankWithdrawIsBankCountryAvailable);

	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const [fakeError, setFakeError] = useState<boolean>(false);

    const mapWithdrawTypeToBackClick = (isIndex: boolean) => {
		dispatch(setWithdrawInit());
		if (withdrawType === 'bank') {
			navigate(
				isIndex ? ROUTES.WITHDRAW.BANK.INDEX : ROUTES.WITHDRAW.BANK.CONFIRMATION
			);
		}
		if (withdrawType === 'crypto') {
			navigate(
				isIndex
					? ROUTES.WITHDRAW.CRYPTO.INDEX
					: ROUTES.WITHDRAW.CRYPTO.CONFIRMATION
			);
		}
		if (withdrawType === 'card') {
			navigate(
				isIndex ? ROUTES.WITHDRAW.CARD.INDEX : ROUTES.WITHDRAW.CARD.CONFIRMATION
			);
		}
	};

	const mapParamIdToValue = (paramId: TBankWithdrawParamIds) => {
		switch (paramId) {
			case 'currencyCode':
				return selectedAsset?.code;
			case 'beneficiaryAccountNumber':
				return ibanAccountNumber;
			case 'bankCode':
				return bankCode;
			case 'paymentSystem':
				return paymentSystem;
			case 'bankName':
				return bankName;
			case 'bankCountry':
				return selectBankCountry?.valueList[0];
			case 'taxIdentNumber':
				return taxIdentNumber;
			case 'corrAccount':
				return corrAccount;
			default:
				return '';
		}
	};

	const mapWithdrawTypeToMethod = (values: string) => {
		if (withdrawType === 'bank') {
			sendBankWithdraw({
				accountNumber: mainEWallet,
				amount: Number(amount),
				params: bankParams?.params?.map((param) => {
					return {
						paramId: param.paramId,
						paramName: param.paramName,
						paramValue: mapParamIdToValue(param?.paramId) as string,
					};
				}) as TBankWithdrawBodyParam[],
				twoFA: {
					state: state,
					code: values,
					identityType: selectedIdentity,
				},
				currencyCode: selectedAsset?.code as string,
				dispatch: dispatch,
				accessToken: accessToken as string,
			});
		}
		if (withdrawType === 'crypto') {
			sendCryptoWithdraw({
				accountNumber: accountNumber,
				accessToken: accessToken as string,
				amount: cryptoAmount,
				cryptoAddress: address,
				assetCode: cryptoSelectedAsset?.code as string,
				networkCode: selectedNetwork?.networkCode as string,
				networkName: selectedNetwork?.networkName as string,
				twoFa: {
					state: state,
					code: values,
					identityType: selectedIdentity,
				},
				dispatch: dispatch,
			});
		}
		if (withdrawType === 'card') {
			sendCardWithdraw({
				accountNumber: accountNumber,
				accessToken: accessToken as string,
				amount: cardAmount,
				currencyCode: cardSelectedAsset?.code as string,
				userCardId: savedCard?.userCardId as string,
				twoFa: {
					state: state,
					code: values,
					identityType: selectedIdentity,
				},
				dispatch: dispatch,
			});
		}
	};

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				mapWithdrawTypeToMethod(values);
			}
		},
		type: 'numeric',
		placeholder: '',
	});

	const { sendBankWithdraw, isWithdrawError, isWithdrawPending, withdrawError } =
		useSendBankWithdraw(navigate, dispatch, accessToken as string);

	const {
		isError: isCardError,
		error: cardError,
		sendCardWithdraw,
		isPending: cardPending,
	} = useSendCardWithdraw(dispatch, navigate, accessToken as string);

	const {
		isError: isCryptoError,
		error: cryptoError,
		sendCryptoWithdraw,
		isPending: cryptoPending,
	} = useSendCryptoWithdraw(dispatch, navigate, accessToken as string);

	useEffect(() => {
		if (identityTypes.length === 0) {
            mapWithdrawTypeToBackClick(true);
		}
	}, []);

	useEffect(() => {
		if (counter == 0) {
			dispatch(setIsResendCodeActive(true));
			dispatch(updateCounter(60));
		}
		const timer = counter > 0 && setInterval(() => dispatch(reduceCounter()), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	const { sendWithdrawOTP } = useSendWithdrawOTP();

	const { t } = useTranslation();

	useEffect(() => {
		if (isWithdrawError || isCardError || isCryptoError) {
			setFakeError(isWithdrawError || isCardError || isCryptoError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isWithdrawError, isCardError, isCryptoError]);

	const handleBackClickButton = () => {
		mapWithdrawTypeToBackClick(false);
	};

	const handleResendCode = () => {
		sendWithdrawOTP({
			identityType: selectedIdentityType,
			state: state,
			dispatch: dispatch,
			accessToken: accessToken as string,
		});
	};

	const handlePasteClick = async () => {
		const clipboardContent = await navigator.clipboard.readText();
		if (
			clipboardContent &&
			clipboardContent.length === 6 &&
			!isNaN(Number(clipboardContent))
		) {
			setValues(clipboardContent.split(''));
			setFakeError(false);

			mapWithdrawTypeToMethod(clipboardContent);
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div className={mapClassnameForMobile('walletLayout', true)}>
					<div className="innerContainer">
						<h1 className={'confirmEmailText'}>
							{t(
								selectedIdentityType.toLocaleLowerCase() === 'email'
									? 'twoFactorAuthMethods_email'
									: 'twoFactorAuthMethods_phone'
							)}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_subtitle') +
								' ' +
								(selectedIdentityType.toLowerCase() === 'email'
									? t('login_email')
									: t('registration_phone'))}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={
									cardPending || cryptoPending || isWithdrawPending
								}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						{isResendCodeActive ? (
							<h1 className={'resendCodeText'} onClick={handleResendCode}>
								{t('general_resendCode')}
							</h1>
						) : (
							<h1 className={'resendCodeText-inactive'}>
								{t('general_resendCodeIn')} {counter}
							</h1>
						)}
						<APIError
							isError={fakeError}
							error={cardError || cryptoError || withdrawError}
						/>
					</div>
				</div>
				{!isTelegram && (
					<div className="pasteButtonContainer">
						<CustomButton
							isGrey
							text={t('general_pasteCode') as string}
							onClick={handlePasteClick}
							className="confirmFullWidthButton"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default WithdrawOTPLayout;
