import { useEffect, useState } from 'react';
import { PinInput } from '../../../common/inputs';
import { CustomButton } from '../../../common/buttons';
import { ROUTES } from '../../../../types/routes';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { usePinInput } from 'react-pin-input-hook';
import {
	useSendPasswordRecoveryChange,
	useSendPasswordRecoveryRequest,
} from '../../../../data/mutations/login/login';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { APIError } from '../../../common/error';
import { mapClassnameForMobile } from '../../../../utils';
import {
	reduceCounter,
	selectCounter,
	selectIsResendCodeActive,
	selectIsTelegram,
	setIsResendCodeActive,
	updateCounter,
} from '../../../../services/root';
import { selectPasswordRecoveryEmail } from '../../../../services/auth';
import { HeaderLayout } from '../../../common/header';

const PasswordRecoveryOTPLayout = () => {
	const passwordRecoveryEmail = useAppSelector(selectPasswordRecoveryEmail);

	const isTelegram = useAppSelector(selectIsTelegram);
	const dispatch = useAppDispatch();
	const counter = useAppSelector(selectCounter);
	const isResendCodeActive = useAppSelector(selectIsResendCodeActive);

	const [fakeError, setFakeError] = useState<boolean>(false);

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				sendPasswordRecoveryChange({
					email: passwordRecoveryEmail,
					newPassword: null,
					code: values,
				});
			}
		},
		type: 'numeric',
		placeholder: '',
	});

	const navigate = useCustomNavigate();

	useEffect(() => {
		if (passwordRecoveryEmail.length === 0) {
			navigate(ROUTES.AUTH.RECOVERY.PASSWORD_RECOVERY);
		}
	}, []);

	useEffect(() => {
		if (counter == 0) {
			dispatch(setIsResendCodeActive(true));
			dispatch(updateCounter(60));
		}
		const timer = counter > 0 && setInterval(() => dispatch(reduceCounter()), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	const { sendPasswordRecoveryRequest } = useSendPasswordRecoveryRequest(
		navigate,
		true
	);
	const { isError, error, sendPasswordRecoveryChange, isPending } =
		useSendPasswordRecoveryChange(navigate);

	const { t } = useTranslation();

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	const handleBackClickButton = () => {
		navigate(ROUTES.AUTH.RECOVERY.PASSWORD_RECOVERY);
	};

	const handleResendCode = () => {
		setFakeError(false);
		sendPasswordRecoveryRequest({
			email: passwordRecoveryEmail,
		});
		dispatch(setIsResendCodeActive(false));
		dispatch(updateCounter(60));
	};

	const handlePasteClick = async () => {
		const clipboardContent = await navigator.clipboard.readText();
		if (
			clipboardContent &&
			clipboardContent.length === 6 &&
			!isNaN(Number(clipboardContent))
		) {
			setValues(clipboardContent.split(''));
			setFakeError(false);

			if (values.length == 6) {
				sendPasswordRecoveryChange({
					email: passwordRecoveryEmail,
					newPassword: null,
					code: clipboardContent,
				});
			}
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div className={mapClassnameForMobile('walletLayout', true)}>
					<div className="innerContainer">
						<h1 className={'confirmEmailText'}>
							{t('twoFactorAuthMethods_email')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_subtitle') +
								' ' +
								passwordRecoveryEmail}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={isPending}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						{isResendCodeActive ? (
							<h1 className={'resendCodeText'} onClick={handleResendCode}>
								{t('general_resendCode')}
							</h1>
						) : (
							<h1 className={'resendCodeText-inactive'}>
								{t('general_resendCodeIn')} {counter}
							</h1>
						)}
						<APIError isError={fakeError} error={error} />
					</div>
				</div>
				{!isTelegram && (
					<div className="pasteButtonContainer">
						<CustomButton
							isGrey
							text={t('general_pasteCode') as string}
							onClick={handlePasteClick}
							className="confirmFullWidthButton"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default PasswordRecoveryOTPLayout;
