import { fetchSecuritySendOtp } from '../api/security';
import { fetchCryptoWithdrawTemplate } from '../api/withdraw';
import { logout } from '../services/auth';
import { setDictionary } from '../services/dictionaries';
import { setAppSettings } from '../services/root';
import {
	setSecurityIdentityTypes,
	setSecuritySelectedIdentityType,
	setSecurityState,
} from '../services/security';
import { AppDispatch } from '../store';
import { ROUTES } from '../types/routes';
import {
	TInnerCryptoBaseAsset,
	TCustomNavigate,
	TGlobalRouteLoaderResponse,
	TInnerAssetBaseAvailableAsset,
	TInnerCryptoWithdraw,
	TIdentity,
} from '../types/types';
import { NumericFormat } from 'react-number-format';

const mapClassnameForMobile = (className: string, isNeedAuthMainContainer?: boolean) => {
	if (window.innerWidth <= 480) {
		return className + ' topPlacement';
	}
	return className + (isNeedAuthMainContainer ? ' authMainContainer' : '');
};

export const TWOFA_UTILS = {
	GOOGLE_AUTHENTICATOR: 'GoogleAuthenticator',
	EMAIL: 'Email',
	PHONE: 'Phone',
};

const mapTranslationKeyHeaderByName = (pageName: string) => {
	switch (pageName) {
		case 'wallet':
			return 'wallet_title';
		case 'market':
			return 'assets_title';
		case 'exchange':
			return 'wallet_exchange';
		default:
			return '';
	}
};

const mapDictionaryByName = (dictionaryName: string) => {
	switch (dictionaryName) {
		case 'countries':
			return 'countries';
		case 'legalStatements':
			return 'documents';
		case 'help':
			return 'help';
		case 'community':
			return 'community';
		case 'assetIcons':
			return 'asseticons';
		default:
			return '';
	}
};

const formatNumber = (
	number: number | string,
	precision?: number,
	splitBy?: string,
	splitByPart?: number
) => {
	return (
		<NumericFormat
			displayType={'text'}
			value={
				splitBy
					? String(number)
							?.split(splitBy)
							[splitByPart as number]?.substring(
								0,
								splitByPart === 1 ? precision : undefined
							)
					: number
			}
			thousandSeparator={splitByPart === 1 ? '' : ' '}
			decimalSeparator={','}
			decimalScale={precision ? precision : 2}
		/>
	);
};

const setDefaultDictionaries = (
	data: TGlobalRouteLoaderResponse,
	dispatch: AppDispatch,
	navigate: TCustomNavigate
) => {
	dispatch(
		setDictionary(
			Object.assign((data as TGlobalRouteLoaderResponse)?.healthData, {
				dictionaryName: 'health',
			})
		)
	);

	if ((data as TGlobalRouteLoaderResponse)?.healthData?.title) {
		navigate(ROUTES.MAINTAIN.INDEX);
	}

	dispatch(
		setDictionary(
			Object.assign((data as TGlobalRouteLoaderResponse)?.assetIconsData, {
				dictionaryName: 'assetIcons',
			})
		)
	);

	dispatch(
		setDictionary(
			Object.assign(
				(data as TGlobalRouteLoaderResponse)?.depositWithdrawAssetsData,
				{
					dictionaryName: 'depositWithdrawAssets',
				}
			)
		)
	);

	dispatch(
		setDictionary(
			Object.assign((data as TGlobalRouteLoaderResponse)?.marketTabsData, {
				dictionaryName: 'marketTabs',
			})
		)
	);

	dispatch(
		setDictionary(
			Object.assign((data as TGlobalRouteLoaderResponse)?.registrationData, {
				dictionaryName: 'registration',
			})
		)
	);

	dispatch(setAppSettings((data as TGlobalRouteLoaderResponse)?.appSettingsData));
};

const mapResponseError = (
	response: Response,
	//eslint-disable-next-line
	responseJSON: any,
	navigate?: TCustomNavigate,
	dispatch?: AppDispatch
) => {
	const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

	if (
		response.status === 401 &&
		responseJSON.code != 'VERIFICATION_CODE_INVALID' &&
		responseJSON.code != 'MULTI_FACTOR_REQUIRED'
	) {
		dispatch && dispatch(logout());
		window.location.replace(ROUTES.AUTH.INDEX.replace(':locale', locale));
		throw responseJSON;
	}
	if (response.status === 503) {
		window.location.replace(ROUTES.MAINTAIN.INDEX.replace(':locale', locale));
		throw responseJSON;
	}

	throw responseJSON;
};

export {
	mapDictionaryByName,
	mapClassnameForMobile,
	mapResponseError,
	mapTranslationKeyHeaderByName,
	formatNumber,
	setDefaultDictionaries,
};

export function filterCryptoAssetsBySearchValue<T extends TInnerCryptoBaseAsset>(
	this: { value: string },
	value: T
) {
	return (
		value?.code?.toLowerCase()?.includes(this?.value?.toLowerCase()) ||
		value?.name?.toLowerCase()?.includes(this?.value?.toLowerCase())
	);
}

export function reduceCryptoAssetsToUniqueValues<T extends TInnerCryptoBaseAsset, C>(
	this: C[],
	previousValue: T[],
	currentValue: T
) {
	if (!previousValue.some((asset) => asset?.code === currentValue?.code)) {
		previousValue.push(currentValue as T);
	}
	return previousValue;
}

export function sortAssetsByBaseAvailable<T extends TInnerAssetBaseAvailableAsset>(
	a: T,
	b: T
) {
	if (a?.baseAvailable > b?.baseAvailable) {
		return -1;
	} else if (a?.baseAvailable < b?.baseAvailable) {
		return 1;
	} else {
		return 0;
	}
}

export async function getCryptoTemplates(
	this: {
		dispatch: AppDispatch;
		accessToken: string;
	},
	element: TInnerCryptoWithdraw
) {
	return await fetchCryptoWithdrawTemplate({
		networkName: element?.networkName?.replace(' ', '') as string,
		dispatch: this?.dispatch,
		accessToken: this?.accessToken as string,
	});
}

export async function handleAuthorizationErrorState(
	dispatch: AppDispatch,
	navigate: TCustomNavigate,
	accessToken: string,
	//eslint-disable-next-line
	jsonErrorBody: any
) {
	if (jsonErrorBody.code === 'MULTI_FACTOR_REQUIRED') {
		dispatch && dispatch(setSecurityState(jsonErrorBody?.data?.state));
		dispatch &&
			dispatch(
				setSecurityIdentityTypes(
					jsonErrorBody.data.identityTypes.map(
						//eslint-disable-next-line
						(identityType: any) =>
							(identityType = {
								key: identityType.name,
								value: identityType.name,
							})
					)
				)
			);
		if (
			jsonErrorBody.data.identityTypes.length === 1 &&
			jsonErrorBody.data.identityTypes.find(
				(identity: TIdentity) => identity.name === 'GoogleAuthenticator'
			)
		) {
			navigate(ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.TWO_FA);
			throw jsonErrorBody;
		}
		if (
			jsonErrorBody.data.identityTypes.length === 1 &&
			jsonErrorBody.data.identityTypes.find(
				(identity: TIdentity) => identity.name === 'Email'
			)
		) {
			dispatch && dispatch(setSecuritySelectedIdentityType('email'));
			await fetchSecuritySendOtp({
				identityType: 'email',
				state: jsonErrorBody?.data?.state,
				dispatch: dispatch,
				accessToken: accessToken,
			});
			navigate(ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.OTP);
			return jsonErrorBody;
		}
		if (jsonErrorBody.data.identityTypes.length > 1) {
			dispatch &&
				dispatch(
					setSecurityIdentityTypes(
						jsonErrorBody.data.identityTypes.map(
							//eslint-disable-next-line
							(identityType: any) =>
								(identityType = {
									key: identityType.name,
									value: identityType.name,
								})
						)
					)
				);
			navigate(ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.SELECT_TWO_FA);
			throw jsonErrorBody;
		}
	}
	throw jsonErrorBody;
}
