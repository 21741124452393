import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import { TBankWithdrawInputKeys, TBankWithdrawParam, TBankWithdrawParams, TInnerFiatWithdraw } from '../../types/types';

function mapParamToValue(param: TBankWithdrawParam) {
	if (param?.valueList === undefined && param?.paramValue === undefined) {
		return '';
	}
	return param?.valueList != undefined && param?.valueList?.length > 0 ? param?.valueList[0] : param?.paramValue;
}

export const selectBankWithdrawFee = (state: TRootState) => state.withdrawBank.fee;

export const selectBankWithdrawAsset = (state: TRootState) =>
	state.withdrawBank.selectedAsset;

export const selectBankWithdrawAmount = (state: TRootState) => state.withdrawBank.amount;

export const selectBankWithdrawIsChecked = (state: TRootState) =>
	state.withdrawBank.isChecked;

export const selectBankWithdrawOperationId = (state: TRootState) =>
	state.withdrawBank.lastWithdrawOperationId;

export const selectBankWithdrawParams = (state: TRootState) =>
	state.withdrawBank.withdrawParams;

export const selectBankWithdrawIbanAccountNumber = (state: TRootState) =>
	state.withdrawBank.ibanAccountNumber;

export const selectBankWithdrawPaymentSystem = (state: TRootState) =>
	state.withdrawBank.paymentSystem;

export const selectBankWithdrawTaxIdentNumber = (state: TRootState) =>
	state.withdrawBank.taxIdentNumber;

export const selectBankWithdrawCorrAccount = (state: TRootState) =>
	state.withdrawBank.corrAccount;

export const selectBankWithdrawBankName = (state: TRootState) =>
	state.withdrawBank.bankName;

export const selectBankWithdrawBankCode = (state: TRootState) =>
	state.withdrawBank.bankCode;

export const selectBankWithdrawBankCountry = (state: TRootState) => state.withdrawBank.bankCountry;

export const selectBankWithdrawCorrBankCode = (state: TRootState) =>
	state.withdrawBank.corrBankCode;

export const selectBankWithdrawCorrBankName = (state: TRootState) =>
	state.withdrawBank.corrBankName;

export const selectBankWithdrawIsSelectAssetAvailable = (state: TRootState) =>
	state.withdrawBank.withdrawParams?.params?.find(
		(param) => param.paramId === 'currencyCode'
	);

export const selectBankWithdrawIsBeneficiaryAccountAvailable = (state: TRootState) =>
	state.withdrawBank.withdrawParams?.params?.find(
		(param) => param.paramId === 'beneficiaryAccountNumber'
	);

export const selectBankWithdrawIsBankCodeAvailable = (state: TRootState) =>
	state.withdrawBank.withdrawParams?.params?.find(
		(param) => param.paramId === 'bankCode'
	);

export const selectBankWithdrawIsBankNameAvailable = (state: TRootState) =>
	state.withdrawBank.withdrawParams?.params?.find(
		(param) => param.paramId === 'bankName'
	);

export const selectBankWithdrawIsPaymentSystemAvailable = (state: TRootState) =>
	state.withdrawBank.withdrawParams?.params?.find(
		(param) => param.paramId === 'paymentSystem'
	);

export const selectBankWithdrawIsTaxIdentNumberAvailable = (state: TRootState) =>
	state.withdrawBank.withdrawParams?.params?.find(
		(param) => param.paramId === 'taxIdentNumber'
	);

export const selectBankWithdrawIsCorrAccountAvailable = (state: TRootState) =>
	state.withdrawBank.withdrawParams?.params?.find(
		(param) => param.paramId === 'corrAccount'
	);

export const selectBankWithdrawIsCorrBankCodeAvailable = (state: TRootState) =>
	state.withdrawBank.withdrawParams?.params?.find(
		(param) => param.paramId === 'corrBankCode'
	);

export const selectBankWithdrawIsCorrBankNameAvailable = (state: TRootState) =>
	state.withdrawBank.withdrawParams?.params?.find(
		(param) => param.paramId === 'corrBankName'
	);

export const selectBankWithdrawIsBankCountryAvailable = (state: TRootState) =>
	state.withdrawBank.withdrawParams?.params?.find(
		(param) => param.paramId === 'bankCountry'
	);

export const selectInputRequired = (state: TRootState) => state.withdrawBank.inputRequired;

interface IRootInitialState {
	fee: string;
	selectedAsset: TInnerFiatWithdraw | undefined;
	ibanAccountNumber: string;
	amount: string;
	isChecked: boolean;
	lastWithdrawOperationId: string;
	withdrawParams: TBankWithdrawParams | undefined;
	bankCode: string;
	bankName: string;
	bankCountry: string;
	paymentSystem: string;
	taxIdentNumber: string;
	corrAccount: string;
	corrBankName: string;
	corrBankCode: string;
	inputRequired: TBankWithdrawInputKeys;
}

const initialState: IRootInitialState = {
	fee: '0',
	selectedAsset: undefined,
	amount: '',
	isChecked: false,
	lastWithdrawOperationId: '',
	withdrawParams: undefined,
	ibanAccountNumber: '',
	bankCode: '',
	bankName: '',
	bankCountry: '',
	paymentSystem: '',
	taxIdentNumber: '',
	corrAccount: '',
	corrBankName: '',
	corrBankCode: '',
	inputRequired: {
		iban: false,
		bankName: false,
		bankCode: false,
		bankCountry: false,
		taxIdentNumber: false,
		corrAccount: false,
		corrBankCode: false,
		corrBankName: false,
	}
};

export const withdrawBankSlice = createSlice({
	name: 'withdrawBank',
	initialState,
	reducers: {
		setIsInputRequired(state, action) {
			state.inputRequired = {
				...state.inputRequired,
				[action.payload.key]: action.payload.value
			}
		},
		setBankWithdrawFee(state, action) {
			state.fee = action.payload;
		},
		setBankWithdrawAsset(state, action) {
			state.selectedAsset = action.payload;
		},
		setBankWithdrawAmount(state, action) {
			state.amount = action.payload;
		},
		setBankWithdrawIsChecked(state, action) {
			state.isChecked = action.payload;
		},
		setBankWithdrawOperationId(state, action) {
			state.lastWithdrawOperationId = action.payload;
		},
		setBankWithdrawParams(state, action) {
			state.withdrawParams = action.payload;
			action.payload?.params.forEach((param: TBankWithdrawParam) => {
				switch (param?.paramId) {
					case 'bankName': {
						state.bankName = mapParamToValue(param);
						break;
					}
					case 'beneficiaryAccountNumber': {
						state.ibanAccountNumber = mapParamToValue(param);
						break;
					}
					case 'paymentSystem': {
						state.paymentSystem = mapParamToValue(param);
						break;
					}
					case 'corrAccount': {
						state.corrAccount = mapParamToValue(param);
						break;
					}
					case 'taxIdentNumber': {
						state.taxIdentNumber = mapParamToValue(param);
						break;
					}
					case 'bankCode': {
						state.bankCode = mapParamToValue(param);
						break;
					}
					case 'bankCountry': {
						state.bankCountry = mapParamToValue(param);
						break;
					}
					case 'corrBankName': {
						state.corrBankName = mapParamToValue(param);
						break;
					}
					case 'corrBankCode': {
						state.corrBankCode = mapParamToValue(param);
						break;
					}
					default:
						return;
				}
			});
		},
		setBankWithdrawBankCode(state, action) {
			state.bankCode = action.payload;
		},
		setBankWithdrawBankCountry(state, action) {
			state.bankCountry = action.payload;
		},
		setBankWithdrawIban(state, action) {
			state.ibanAccountNumber = action.payload;
		},
		setBankWithdrawBankName(state, action) {
			state.bankName = action.payload;
		},
		setBankWithdrawPaymentSystem(state, action) {
			state.paymentSystem = action.payload;
		},
		setBankWithdrawTaxIdentNumber(state, action) {
			state.taxIdentNumber = action.payload;
		},
		setBankWithdrawCorrAccount(state, action) {
			state.corrAccount = action.payload;
		},
		setBankWithdrawCorrBankCode(state, action) {
			state.corrBankCode = action.payload;
		},
		setBankWithdrawCorrBankName(state, action) {
			state.corrBankName = action.payload;
		},
		setBankWithdrawInit(state) {
			state.fee = '0';
			state.amount = '';
			state.selectedAsset = undefined;
			state.isChecked = false;
			state.lastWithdrawOperationId = '';
			state.withdrawParams = undefined;
			state.ibanAccountNumber = '';
			state.bankCode = '';
			state.bankName = '';
			state.paymentSystem = '';
			state.taxIdentNumber = '';
			state.corrAccount = '';
			state.corrBankCode = '';
			state.corrBankName = '';
			state.inputRequired = {
				iban: false,
				bankName: false,
				bankCode: false,
				bankCountry: false,
				taxIdentNumber: false,
				corrAccount: false,
				corrBankCode: false,
				corrBankName: false,
			}
		},
	},
});

export const {
	setBankWithdrawFee,
	setBankWithdrawAmount,
	setBankWithdrawAsset,
	setBankWithdrawInit,
	setBankWithdrawIsChecked,
	setBankWithdrawOperationId,
	setBankWithdrawParams,
	setBankWithdrawIban,
	setBankWithdrawBankName,
	setBankWithdrawCorrAccount,
	setBankWithdrawPaymentSystem,
	setBankWithdrawTaxIdentNumber,
	setBankWithdrawBankCode,
	setBankWithdrawCorrBankName,
	setBankWithdrawCorrBankCode,
	setBankWithdrawBankCountry,
	setIsInputRequired
} = withdrawBankSlice.actions;
