import { useTranslation } from 'react-i18next';
import {
	TAsset,
	TBankCountry,
	TCountry,
	TExchangeItem,
	THistoryDatePeriod,
	THistoryDatePeriodWithDate,
	THistoryPeriod,
	TInnerBank,
	TInnerBankCurrencies,
	TInnerCryptoDeposit,
	TInnerCryptoDepositAsset,
	TInnerCryptoDepositModified,
	TInnerCryptoWithdraw,
	TInnerCryptoWithdrawAsset,
	TInnerCryptoWithdrawModified,
	TInnerFiatDeposit,
	TInnerFiatWithdraw,
	TInnerFiatWithdrawModified,
	TMarketAsset,
	TMarketTab,
	TOperation,
	TSaveAddressSelectType,
	TSaveAddressTabs,
	TSavedAddress,
	TSavedBank,
	TSavedCard,
	TTransferParams,
	TWalletModalItem,
} from '../../../types/types';
import { Backdrop, Fade, Modal } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { CustomInput } from '../inputs';
import {
	CountryModalItem,
	HistoryPeriodModalItem,
	OTPModalItem,
	SaveAddressTabContainer,
} from './items';
import {
	CopyGreenIcon,
	CopyIcon,
	EllipseCheckBoxIcon,
	OperationStatusIcon,
	RightChev,
	SelectedTimeFrameIcon,
	ThemeIcons,
	VerificationStarIcon,
	WhaleIcon,
} from '../icons';
import { CustomButton } from '../buttons';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectAssetSymbolsDictionary,
	selectBanksDictionary,
	selectCountriesDictionary,
	selectDepositWithdrawAssetsDictionary,
	selectMarketTabs,
	selectPeriodFiltersDictionary,
} from '../../../services/dictionaries';
import {
	selectAppSettings,
	selectLocale,
	selectTheme,
	setTheme,
} from '../../../services/root';
import {
	ExchangeFromItem,
	MarketAssetItem,
	WalletItem,
	WalletModalItem,
} from '../lists/items';
import {
	HistoryItemList,
	SavedAddressItemList,
	SavedBanksItemList,
	SavedCardsItemList,
	WalletItemList,
} from '../lists';
import { CustomCheckbox } from '../checkboxes';
import {
	clearConvertSelected,
	selectConvertAll,
	selectBaseLowBalance,
	selectBasePrecision,
	selectConvertOne,
	selectWalletConvertAssets,
	selectTransferOne,
	clearTransferSelected,
	selectTransferAll,
	selectWalletTransferToMainRestsAssets,
	selectMainEWallet,
	selectTotalAccountsRests,
	selectIsLeverageTransfer,
	selectSpotAccountsRests,
	selectCommissionAccountRests,
	selectGlobalAsset,
	selectMainAccountRests,
	sortRests,
} from '../../../services/wallet';
import {
	useConvertLowBalancesMutation,
	useTransferToMainRestsMutation,
} from '../../../data/mutations/wallet';
import { selectAccessToken } from '../../../services/auth';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { APIError } from '../error';
import {
	filterCryptoAssetsBySearchValue,
	formatNumber,
	reduceCryptoAssetsToUniqueValues,
	sortAssetsByBaseAvailable,
} from '../../../utils';
import { CustomSwitch } from '../switchs';
import {
	useDisableTwoFaMutation,
	useRevokeTokenMutation,
	useSendGeneralAccountLetterMutation,
	useSendStatementMutation,
} from '../../../data/mutations/profile';
import { selectIsVerified } from '../../../services/user';
import { COMMON_ROUTES, ROUTES } from '../../../types/routes';
import {
	selectCurrentOperation,
	selectDatePeriod,
	selectOperationDetails,
	setOperationDetails,
} from '../../../services/history';
import {
	useGetDefaultHistory,
	useRejectOperation,
} from '../../../data/mutations/history';
import dayjs, { Dayjs } from 'dayjs';
import CustomDatePicker from '../date-pickers';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import { selectMarketAssets } from '../../../services/market';
import { selectCurrentInvoiceId, selectInvoiceInfo } from '../../../services/deposit';
import { BankTransferInvoicePreviewLayout } from '../../deposit/bank/invoice';
import { useDeleteBankDepositInvoice } from '../../../data/mutations/deposit';
import {
	useDeleteBankWithdrawSavedBank,
	useDeleteCardWithdrawSavedCard,
	useDeleteCryptoWithdrawSavedAddresses,
	useGetBankWithdrawSavedBank,
	useGetCardWithdrawSavedCard,
	useGetCryptoWithdrawSavedAddresses,
	useUpdateBankWithdrawSavedBank,
	useUpdateCardWithdrawSavedCard,
	useUpdateCryptoWithdrawSavedAddresses,
} from '../../../data/mutations/withdraw';
import { selectCryptoWithdrawNetwork } from '../../../services/withdraw/crypto.withdraw.slice';
import { selectCardWithdrawCard } from '../../../services/withdraw/card.withdraw.slice';
import {
	selectBankWithdrawCurrentSavedBank,
	selectCryptoWithdrawCurrentSavedAddress,
} from '../../../services/withdraw/templates.withdraw.slice';
import { SavedAddressesTabModalContainer } from './containers';
import {
	selectExchangeCurrentTab,
	selectExchangeFromAsset,
	selectExchangeToAsset,
	setExchangeCurrentTab,
} from '../../../services/exchange/exchange.slice';
import { AssetsNavigationContainer } from '../navigation';
import {
	selectBankWithdrawIsPaymentSystemAvailable,
	selectBankWithdrawPaymentSystem,
} from '../../../services/withdraw/bank.withdraw.slice';
import {
	selectTwoFaOptionsCurrentOption,
	updateTwoFactorOption,
} from '../../../services/security';

interface BaseModalProps {
	isModalOpen: boolean;
	handleIsModalOpen: () => void;
}

interface CountryModalProps extends BaseModalProps {
	handleItemClick: (i: { code: string; imgUrl: string }) => void;
	countries: TCountry[];
}

const CountryModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	countries,
}: CountryModalProps) => {
	const { t } = useTranslation();

	const [searchValue, setSearchValue] = useState<string>('');
	const [isSearchValueRequired, setIsSearchValueRequired] = useState<boolean>(false);

	const [mappedData, setMappedData] = useState<
		{
			code: string;
			name: string;
			link: string;
			phoneCode: string;
			isRestricted: boolean;
		}[]
	>();

	const [modalInset, setModalInset] = useState<string>('');

	useEffect(() => {
		setMappedData(countries);
	}, []);

	useEffect(() => {
		if (window.innerWidth < 480) {
			if (window.innerHeight < 670) {
				setModalInset((window.innerHeight * 425) / 540 + 'px');
				return;
			} else if (window.innerHeight > 670 && window.innerHeight < 916) {
				setModalInset((window.innerHeight * 280) / 684 + 'px');
				return;
			}
			setModalInset('215px');
		} else {
			setModalInset('545px');
		}
	}, [window.innerHeight]);

	useEffect(() => {
		setMappedData(
			countries.filter(
				(item: {
					code: string;
					name: string;
					link: string;
					phoneCode: string;
					isRestricted: boolean;
				}) =>
					item.code.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.phoneCode.toLowerCase().includes(searchValue.toLowerCase())
			)
		);
	}, [searchValue]);

	const handleModalClose = () => {
		handleIsModalOpen();
		setSearchValue('');
		setIsSearchValueRequired(false);
	};

	const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
		setIsSearchValueRequired(e.target.value.length > 0);
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: modalInset,
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponent'}>
					<div className={''}>
						<CustomInput
							className={'modalInput'}
							id={'countryModalSearchInput'}
							value={searchValue}
							label={t('registrationSearch')}
							isRequired={isSearchValueRequired}
							type={'text'}
							onChange={handleSearchValueChange}
						/>
					</div>
					{mappedData && mappedData?.length > 0 ? (
						<div className={'modalContent'}>
							{mappedData?.map((item) => (
								<CountryModalItem
									key={item.code}
									handleModalOpen={handleIsModalOpen}
									item={item}
									handleItemClick={handleItemClick}
									setSearchValue={setSearchValue}
								/>
							))}
						</div>
					) : (
						<div className={'modalContent-empty'}>
							<div className={'modalEmptyContainer'}>
								<WhaleIcon className={'whaleIcon'} />
								<h1 className={'modalEmptyHeader'}>
									{t('exchangeNotFound')}
								</h1>
								<h1 className={'modalEmptyBottom'}>
									{t('exchangeChangeSettingsText')}
								</h1>
							</div>
						</div>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

interface OTPModalProps extends BaseModalProps {
	handleItemClick: (i: { key: string; value: string }) => void;
	identityTypes: {
		key: string;
		value: string;
	}[];
}

const OTPModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	identityTypes,
}: OTPModalProps) => {
	const [mappedData, setMappedData] = useState<
		{
			key: string;
			value: string;
		}[]
	>();

	useEffect(() => {
		setMappedData(identityTypes);
	}, []);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalOtpComponent'}>
					<div className={'modalContent'}>
						{mappedData?.map((item) => (
							<OTPModalItem
								key={item.key}
								handleModalOpen={handleIsModalOpen}
								item={item}
								handleItemClick={handleItemClick}
							/>
						))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface ConfirmRegistrationModalProps extends BaseModalProps {
	handleConfirm: () => void;
	isLoading: boolean;
	text?: string;
}

const ConfirmRegistrationModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleConfirm,
	isLoading,
	text,
}: ConfirmRegistrationModalProps) => {
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalOtpComponent'}>
					<h1 className={'confirmEmailText'}>
						{text ? text : t('confirmModalHeader')}
					</h1>
					<div className={'modalContent'}>
						<div className={'confirmButtonContainer'}>
							<CustomButton
								isGrey
								text={t('confirmModalFirstButton') as string}
								type={'submit'}
								onClick={handleIsModalOpen}
								className={'confirmButton'}
							/>
							<CustomButton
								text={t('confirmModalSecondButton') as string}
								type={'submit'}
								onClick={handleConfirm}
								className={'confirmButton'}
								isLoading={isLoading}
								isDisabled={false}
							/>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface MarketTimeFrameModalProps extends BaseModalProps {
	handleItemClick: (i: string) => void;
	currentTimeFrame: string;
}

const MarketTimeFrameModal = ({
	handleIsModalOpen,
	handleItemClick,
	isModalOpen,
	currentTimeFrame,
}: MarketTimeFrameModalProps) => {
	const { t } = useTranslation();

	const timeFrames = [
		{
			text: `1 ${t('filters_hour')}`,
			toSelectValue: `1${t('filters_h')}`,
		},
		{
			text: `24 ${t('filters_hours')}`,
			toSelectValue: `24${t('filters_h')}`,
		},
		{
			text: `7 ${t('filters_days')}`,
			toSelectValue: `7${t('filters_d')}`,
		},
		{
			text: `30 ${t('filters_days')}`,
			toSelectValue: `30${t('filters_d')}`,
		},
	];

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};
	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalMarketTimeFrameComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('filters_timeframe')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalMarketTimeFrameInnerContainer'}>
						{timeFrames.map((timeFrame, idx) => (
							<div
								className="modalMarketTimeFrameInnerItem"
								onClick={() => handleItemClick(timeFrame.toSelectValue)}
								key={idx}
							>
								<h1 className="modalMarketTimeFrameInnerText">
									{timeFrame.text}
								</h1>
								{timeFrame.toSelectValue === currentTimeFrame && (
									<SelectedTimeFrameIcon />
								)}
							</div>
						))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const ThemeModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: LocaleModalProps) => {
	const { t } = useTranslation();
	const theme = useAppSelector(selectTheme);
	const [isActive, setIsActive] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleDeviceThemeClick = () => {
		const tempIsActive = !isActive;
		setIsActive(tempIsActive);
		if (tempIsActive) {
			const darkThemeMq = window.matchMedia(
				'(prefers-color-scheme: dark)'
			)?.matches;
			dispatch(setTheme(darkThemeMq ? 'dark' : 'light'));
		} else {
			dispatch(setTheme('light'));
		}
	};

	useEffect(() => {
		if (isModalOpen) {
			if (
				window.matchMedia('(prefers-color-scheme: dark)')?.matches &&
				theme === 'dark'
			) {
				setIsActive(true);
			} else if (
				!window.matchMedia('(prefers-color-scheme: dark)')?.matches &&
				theme === 'light'
			) {
				setIsActive(true);
			}
		}
	}, [isModalOpen]);

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalThemeComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('settings_theme')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className="modalThemeOuterContainer">
						<div className={'modalThemeInnerContainer'}>
							<div className="themeHeaderTextContainer">
								<h1 className="themeHeaderMainText">
									{t('settings_useDeviceSettings')}
								</h1>
								<h1 className="themeHeaderBottomText">
									{t('settings_appWillUseDeviceMode')}
								</h1>
							</div>
							<div className="">
								<CustomSwitch
									setIsActive={setIsActive}
									isActive={isActive}
									onClick={handleDeviceThemeClick}
								/>
							</div>
						</div>
						<div className="modalThemeSelectContainer">
							<div
								onClick={() => handleItemClick('light')}
								className="modalThemeInnerSelectContainer"
							>
								<ThemeIcons.LightThemeSelectIcon />
								<h1 className="modalThemeInnerSelectText">
									{t('settings_light')}
								</h1>
								<EllipseCheckBoxIcon
									className="themeCheckboxIcon"
									isActive={theme === 'light'}
								/>
							</div>
							<div
								onClick={() => handleItemClick('dark')}
								className="modalThemeInnerSelectContainer"
							>
								<ThemeIcons.DarkThemeSelectIcon />
								<h1 className="modalThemeInnerSelectText">
									{t('settings_dark')}
								</h1>
								<EllipseCheckBoxIcon
									className="themeCheckboxIcon"
									isActive={theme === 'dark'}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface LocaleModalProps extends BaseModalProps {
	handleItemClick: (i: string) => void;
}

const LocaleModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: LocaleModalProps) => {
	const countries = useAppSelector(selectCountriesDictionary);
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalOtpComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('settings_appLanguage')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						<div
							className="modalLocaleItem"
							onClick={() => handleItemClick('en')}
						>
							<img
								className="countryImage"
								src={
									countries?.dictionary?.find(
										(country: TCountry) => country?.code === 'US'
									)?.link
								}
								alt="US"
							/>
							<h1 className="modalLocaleText">{t('general_english')}</h1>
						</div>
						<div
							className="modalLocaleItem"
							onClick={() => handleItemClick('ru')}
						>
							<img
								className="countryImage"
								src={
									countries?.dictionary?.find(
										(country: TCountry) => country?.code === 'RU'
									)?.link
								}
								alt="RU"
							/>
							<h1 className="modalLocaleText">{t('general_russian')}</h1>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface WalletConvertModalProps extends BaseModalProps {
	globalAsset: TWalletModalItem;
}

const WalletConvertModal = ({
	globalAsset,
	isModalOpen,
	handleIsModalOpen,
}: WalletConvertModalProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const walletConvertAssets = useAppSelector(selectWalletConvertAssets);
	const basePrecision = useAppSelector(selectBasePrecision);
	const accessToken = useAppSelector(selectAccessToken);
	const baseLowBalance = useAppSelector(selectBaseLowBalance);

	const [isSummary, setIsSummary] = useState<boolean>(false);
	const [selectedAmount, setSelectedAmount] = useState<number>(0);

	const { convertLowBalances, isError, isPending, error } =
		useConvertLowBalancesMutation(setIsSummary);

	const handleSelectedAmountChange = (i: TAsset, isSelected: boolean, idx: number) => {
		dispatch(
			selectConvertOne({
				idx: idx,
				checked: isSelected,
			})
		);

		if (isSelected) {
			setSelectedAmount(selectedAmount + i.baseAvailable);
		} else {
			setSelectedAmount(selectedAmount - i.baseAvailable);
		}
	};

	const handleModalClose = () => {
		dispatch(clearConvertSelected());
		setIsSummary(false);
		setSelectedAmount(0);
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleAllSelectedClick = (isSelectedEventValue: boolean) => {
		dispatch(selectConvertAll(isSelectedEventValue));

		if (isSelectedEventValue) {
			setSelectedAmount(
				walletConvertAssets.assets.reduce((currentSum, asset) => {
					return currentSum + asset.baseAvailable;
				}, 0)
			);
		} else {
			setSelectedAmount(0);
		}
	};

	const handleSubmit = () => {
		convertLowBalances({
			accessToken: accessToken as string,
			navigate: navigate,
			assets: walletConvertAssets.assets
				.filter((asset, idx) => walletConvertAssets.selected[idx] === true)
				.map((asset) => {
					return asset.code;
				}),
		});
	};

	if (isSummary) {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div className={'modalWalletConvertComponent'}>
						<WhaleIcon className="whaleConvertIcon" />
						<h1 className={'modalWalletConvertSummaryHeader'}>
							{t('operation_conversionProcessedTitle')}
						</h1>
						{isError ? (
							<APIError isError={isError} error={error} />
						) : (
							<h1 className={'modalWalletConvertSummaryBottom'}>
								{t('operation_conversionProcessedDetails')}
							</h1>
						)}

						<CustomButton
							className="closeFullWidthButton"
							isGrey
							text={t('notifications_close') as string}
							onClick={handleModalClose}
						/>
					</div>
				</Fade>
			</Modal>
		);
	}

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletConvertComponent'}>
					<div className={'modalWalletSearchHeader'}>
						<h1 className={'confirmEmailText'}>
							{t('wallet_convertLowBalances_medium')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					{walletConvertAssets.assets.length === 0 ? (
						<div className={'modalWalletSearchContent'}>
							<h1 className="modalWalletEmptyText">
								{t('wallet_convertLowBalancesEmpty')
									.replaceAll('{baseAssetCode}', globalAsset.code)
									.replaceAll(
										'{baseLowBalance}',
										baseLowBalance.toString()
									)}
							</h1>
							<CustomButton
								className=""
								onClick={handleModalClose}
								text={t('general_close') as string}
								isText
							/>
						</div>
					) : (
						<>
							<h1 className="walletConvertModalReceivedContainerHeader">
								{t('wallet_convertLowBalancesDesc')}
							</h1>
							<div className="modalWalletSearchContent">
								<div className="modalWalletConvertSelectAllContainer">
									<CustomCheckbox
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => handleAllSelectedClick(e.target.checked)}
									/>
									<h1 className="modalWalletConvertSelectAllText">
										{t('wallet_selectAllAssets')}
									</h1>
								</div>
								<WalletItemList
									isBalanceHidden={false}
									globalAsset={globalAsset}
									isLowBalanceHidden={false}
									isWalletConvertModal
									handleSelectedAmountChange={
										handleSelectedAmountChange
									}
									selected={walletConvertAssets.selected}
								/>
							</div>
							<div className="walletConvertModalBottomContainer">
								<div className="walletConvertModalReceivedContainer">
									<h1 className="walletConvertModalReceivedContainerHeader">
										{t('withdrawConfirmation_toBeReceived')}
									</h1>
									<h1 className="walletConvertModalReceivedContainerBottom">
										{formatNumber(selectedAmount, basePrecision)}{' '}
										{globalAsset.code}
									</h1>
								</div>
								<CustomButton
									isDisabled={
										!walletConvertAssets.selected.find(
											(isSelected) => isSelected === true
										)
									}
									text={t('wallet_convert_small') as string}
									isLoading={isPending}
									onClick={handleSubmit}
									className="convertButton"
								/>
							</div>
						</>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

interface AssetDetailsTransferModalProps extends BaseModalProps {
	assetCode: string;
}

const AssetDetailsTransferModal = ({
	assetCode,
	isModalOpen,
	handleIsModalOpen,
}: AssetDetailsTransferModalProps) => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const accessToken = useAppSelector(selectAccessToken);
	const mainEWallet = useAppSelector(selectMainEWallet);
	const totalAccountsRests = useAppSelector(selectTotalAccountsRests);

	const { transferToMainRests, isPending } = useTransferToMainRestsMutation(
		undefined,
		handleIsModalOpen
	);

	const spotCurrencies = useAppSelector(selectSpotAccountsRests);
	const commissionCurrencies = useAppSelector(selectCommissionAccountRests);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleSubmit = () => {
		const transferParams: TTransferParams[] = [];
		totalAccountsRests.forEach((accountInfo) => {
			const transferObject: TTransferParams = {
				accNumFrom: accountInfo.accNum,
				assets: [],
			};
			accountInfo.assets.forEach((asset) => {
				if (asset.code === assetCode) {
					transferObject.assets.push(asset.code);
				}
			});
			if (transferObject?.assets.length > 0) {
				transferParams.push(transferObject);
			}
		});

		transferToMainRests({
			accessToken: accessToken as string,
			navigate: navigate,
			accNumTo: mainEWallet,
			transferParams: transferParams,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletConvertComponent'}>
					<div className={'modalWalletSearchHeader'}>
						<h1 className={'confirmEmailText'}>
							{t('wallet_transferToWallet')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<>
						<div className="modalWalletSearchContent">
							<h1 className="walletConvertModalReceivedContainerHeader">
								{t('asset_transferwarn').replace(
									'{amount}',
									String(
										(spotCurrencies?.assets.find(
											(asset) => asset.code === assetCode
										)?.available ?? 0) +
											(commissionCurrencies?.assets.find(
												(asset) => asset.code === assetCode
											)?.available ?? 0)
									) + ` ${assetCode}`
								)}
							</h1>
						</div>
						<div className="modalLogoutOuterContainer">
							<CustomButton
								isGrey
								text={t('asset_transferConfirm') as string}
								isLoading={isPending}
								onClick={handleSubmit}
								className="transferButton"
							/>
							<CustomButton
								text={t('general_cancel') as string}
								onClick={handleModalClose}
								className="transferButton"
							/>
						</div>
					</>
				</div>
			</Fade>
		</Modal>
	);
};

interface WalletTransferRestsToMainModalProps {
	globalAsset: TWalletModalItem;
	handleIsModalOpen: (isLeverageTransfer?: boolean) => void;
	isModalOpen: boolean;
}

const WalletTransferRestsToMainModal = ({
	globalAsset,
	isModalOpen,
	handleIsModalOpen,
}: WalletTransferRestsToMainModalProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const walletTransferAssets = useAppSelector(selectWalletTransferToMainRestsAssets);
	const isLeverageTransfer = useAppSelector(selectIsLeverageTransfer);
	const accessToken = useAppSelector(selectAccessToken);
	const baseLowBalance = useAppSelector(selectBaseLowBalance);
	const mainEWallet = useAppSelector(selectMainEWallet);
	const totalAccountsRests = useAppSelector(selectTotalAccountsRests);

	const [isSummary, setIsSummary] = useState<boolean>(false);

	const { transferToMainRests, isError, isPending, error } =
		useTransferToMainRestsMutation(setIsSummary);

	const handleSelectedAmountChange = (i: TAsset, isSelected: boolean, idx: number) => {
		dispatch(
			selectTransferOne({
				idx: idx,
				checked: isSelected,
			})
		);
	};

	const handleModalClose = () => {
		dispatch(clearTransferSelected());
		setIsSummary(false);
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleAllSelectedClick = (isSelectedEventValue: boolean) => {
		dispatch(selectTransferAll(isSelectedEventValue));
	};

	const handleSubmit = () => {
		const transferParams: TTransferParams[] = [];
		totalAccountsRests.forEach((accountInfo) => {
			const transferObject: TTransferParams = {
				accNumFrom: accountInfo.accNum,
				assets: [],
			};
			accountInfo.assets.forEach((asset) => {
				walletTransferAssets.assets.forEach((selectedAsset, idx) => {
					if (
						asset.code === selectedAsset.code &&
						walletTransferAssets.selected[idx]
					) {
						transferObject.assets.push(asset.code);
					}
				});
			});
			if (transferObject?.assets.length > 0) {
				transferParams.push(transferObject);
			}
		});

		transferToMainRests({
			accessToken: accessToken as string,
			navigate: navigate,
			accNumTo: mainEWallet,
			transferParams: transferParams,
		});
	};

	if (isLeverageTransfer) {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div className={'modalWalletConvertComponent'}>
						<div className={'modalWalletSearchHeader'}>
							<h1 className={'confirmEmailText'}>
								{t('wallet_usingLeverageTitle')}
							</h1>
							<CustomButton
								isClose={true}
								onClick={handleModalClose}
								className={'registrationCloseButtonContainer'}
							>
								<CloseIcon
									sx={{
										fill: 'black',
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
						<div
							style={{
								gap: '16px',
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
							}}
							className={'modalWalletSearchContent'}
						>
							<h1 className="modalWalletEmptyText">
								{t('wallet_usingLeverageInfo')}
							</h1>
							<CustomButton
								className="confirmFullWidthButton"
								onClick={() => window.open(COMMON_ROUTES.LK)}
								text={t('wallet_openWebVersion') as string}
							/>
						</div>
					</div>
				</Fade>
			</Modal>
		);
	}

	if (isSummary) {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div className={'modalWalletConvertComponent'}>
						<WhaleIcon className="whaleConvertIcon" />
						<h1 className={'modalWalletConvertSummaryHeader'}>
							{t('wallet_transferProcessedTitle')}
						</h1>
						{isError ? (
							<APIError isError={isError} error={error} />
						) : (
							<h1 className={'modalWalletConvertSummaryBottom'}>
								{t('operation_conversionProcessedDetails')}
							</h1>
						)}

						<CustomButton
							className="closeFullWidthButton"
							isGrey
							text={t('notifications_close') as string}
							onClick={handleModalClose}
						/>
					</div>
				</Fade>
			</Modal>
		);
	}

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletConvertComponent'}>
					<div className={'modalWalletSearchHeader'}>
						<h1 className={'confirmEmailText'}>
							{t('wallet_transferToWallet')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					{walletTransferAssets.assets.length === 0 ? (
						<div className={'modalWalletSearchContent'}>
							<h1 className="modalWalletEmptyText">
								{t('wallet_youDontHaveAnyAssets')
									.replaceAll('{baseAssetCode}', globalAsset.code)
									.replaceAll(
										'{baseLowBalance}',
										baseLowBalance.toString()
									)}
							</h1>
							<CustomButton
								className=""
								onClick={handleModalClose}
								text={t('wallet_makeFirstDeposit') as string}
							/>
						</div>
					) : (
						<>
							<div className="modalWalletSearchContent">
								<div className="modalWalletConvertSelectAllContainer">
									<CustomCheckbox
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => handleAllSelectedClick(e.target.checked)}
									/>
									<h1 className="modalWalletConvertSelectAllText">
										{t('wallet_selectAllAssets')}
									</h1>
								</div>
								<WalletItemList
									isBalanceHidden={false}
									globalAsset={globalAsset}
									isLowBalanceHidden={false}
									isWalletTransferModal
									selected={walletTransferAssets.selected}
									handleSelectedAmountChange={
										handleSelectedAmountChange
									}
								/>
							</div>
							<div className="walletTransferModalBottomContainer">
								<CustomButton
									isDisabled={
										!walletTransferAssets.selected.find(
											(isSelected) => isSelected === true
										)
									}
									text={t('wallet_transfer') as string}
									isLoading={isPending}
									onClick={handleSubmit}
									className="transferButton"
								/>
							</div>
						</>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

interface CryptoDepositNetworkModalProps extends BaseModalProps {
	handleItemClick: (i: TInnerCryptoDeposit | TInnerCryptoWithdraw) => void;
	currentItem: TInnerCryptoDeposit | TInnerCryptoWithdraw | undefined;
	suitableNetworks: TInnerCryptoDeposit[] | TInnerCryptoWithdraw[] | undefined;
}

const CryptoDepositNetworkModal = ({
	handleIsModalOpen,
	isModalOpen,
	handleItemClick,
	currentItem,
	suitableNetworks,
}: CryptoDepositNetworkModalProps) => {
	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { t } = useTranslation();

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('general_network')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{suitableNetworks?.map((network) => (
							<div
								className="networkItemContainer"
								onClick={() => handleItemClick(network)}
							>
								<div className="networkItemInnerTextContainer">
									<h1 className="networkItemInnerTextHeader">
										{network?.networkCode}
									</h1>
									<h1 className="networkItemInnerTextBottom">
										{network?.networkName}
									</h1>
								</div>
								{currentItem?.networkName === network?.network && (
									<div className="networkSelectIconContainer">
										<OperationStatusIcon status="done" />
									</div>
								)}
							</div>
						))}
						<CustomButton
							className="confirmFullWidthButton"
							onClick={handleModalClose}
							text={t('general_chooseNetwork') as string}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CryptoWithdrawAssetModalProps extends BaseModalProps {
	handleItemClick: (i: TInnerCryptoWithdrawAsset, networkCode?: string) => void;
}

const CryptoWithdrawAssetModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: CryptoWithdrawAssetModalProps) => {
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw?.crypto;
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketAssets = useAppSelector(selectMarketAssets)?.all;
	const mainCurrencies = useAppSelector(selectMainAccountRests);

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	const [searchValue, setSearchValue] = useState<string>('');

	const [selectAssets, setSelectAssets] = useState<TInnerCryptoWithdrawModified[]>([]);

	const [mutatedSelectAssets, setMutatedSelectAssets] = useState<
		TInnerCryptoWithdrawModified[]
	>([]);

	useEffect(() => {
		if (depositWithdrawAssetsDictionary && isModalOpen) {
			if (searchValue != '') {
				setSelectAssets(
					mutatedSelectAssets?.filter(filterCryptoAssetsBySearchValue, {
						value: searchValue,
					})
				);
			} else {
				if (depositWithdrawAssetsDictionary && marketAssets && mainCurrencies) {
					const uniqueAssets = depositWithdrawAssetsDictionary
						.map((network) => {
							return network.assets.map((asset) => {
								return {
									...asset,
									name: marketAssets?.find(
										(marketAsset) => marketAsset?.code === asset?.code
									)?.name as string,
									networkCode: network?.networkCode,
									baseAvailable:
										mainCurrencies?.assets?.find(
											(mainAsset) => mainAsset?.code === asset?.code
										)?.baseAvailable ?? 0,
								};
							});
						})
						.flat()
						.reduce(
							reduceCryptoAssetsToUniqueValues,
							[] as TInnerCryptoWithdrawModified[]
						)
						.sort(sortAssetsByBaseAvailable);
					setMutatedSelectAssets(uniqueAssets);
					setSelectAssets(uniqueAssets);
				}
			}
		}
	}, [
		searchValue,
		depositWithdrawAssetsDictionary,
		marketAssets,
		mainCurrencies,
		isModalOpen,
	]);

	const handleModalClose = () => {
		handleIsModalOpen();
		setSearchValue('');
		setSelectAssets([]);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleClearClick = () => {
		setSearchValue('');
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { t } = useTranslation();

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('withdrawCrypto_withdrawAsset')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<CustomInput
							placeHolder={t('general_searchTitle') as string}
							className={'modalSelectDepositAssetSearchInput'}
							id={'cryptoWithdrawSelectModalSearchInput'}
							label=""
							value={searchValue}
							isRequired={false}
							onClearClick={handleClearClick}
							type={'text'}
							onChange={handleInputChange}
							isSearch
						/>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								{mainCurrencies?.assets &&
								mainCurrencies?.assets?.length > 0 &&
								marketAssets &&
								marketAssets?.length > 0 ? (
									<>
										{selectAssets?.map((asset, idx) => (
											<WalletItem
												basePrecision={
													mainCurrencies?.basePrecision
												}
												isBalanceHidden={false}
												baseAsset={globalAsset.code}
												key={idx}
												idx={idx}
												asset={
													mainCurrencies?.assets?.find(
														(mainCurrency) =>
															mainCurrency?.code ===
															asset?.code
													)
														? mainCurrencies?.assets?.find(
																(mainCurrency) =>
																	mainCurrency?.code ===
																	asset?.code
														  )
														: {
																code: asset?.code,
																name: asset?.name,
																group: 'Crypto',
																precision: 2,
																available: 0,
																reserved: 0,
																baseAvailable: 0,
																baseAvailable00h: 0,
																baseReserved: 0,
																baseReserved00h: 0,
														  }
												}
												withdrawAsset={asset}
												networkCode={asset?.networkCode}
												handleItemClick={handleItemClick}
											/>
										))}
									</>
								) : (
									<>
										{loadingCards.map((card) => (
											<MarketAssetItem
												isLoading
												key={card}
												idx={Number(card)}
											/>
										))}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface ExchangeSelectAssetModalProps extends BaseModalProps {
	handleItemClick: (i: TExchangeItem | undefined) => void;
	isFrom: boolean;
}

const ExchangeSelectFromAssetModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: ExchangeSelectAssetModalProps) => {
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketAssets = useAppSelector(selectMarketAssets)?.all;
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const selectedToAsset = useAppSelector(selectExchangeToAsset);

	const assetSymbols = useAppSelector(selectAssetSymbolsDictionary);

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	const [searchValue, setSearchValue] = useState<string>('');

	function filterCurrenciesBySearchValue(
		currencies: TMarketAsset[] | undefined,
		searchValue: string | undefined
	) {
		if (currencies && mainCurrencies?.assets) {
			let mappedCurrencies = currencies.map((asset) => {
				const mainAsset = mainCurrencies?.assets?.find(
					(mainAsset) => mainAsset.code === asset.code
				);

				let copiedAssetWithRightPrecision;
				if (mainAsset) {
					copiedAssetWithRightPrecision = assetSymbols?.dictionary?.find(
						(symbol) =>
							symbol?.asset1?.code === mainAsset?.code ||
							symbol?.asset2?.code === mainAsset?.code
					);
				}

				return {
					...asset,
					precision: copiedAssetWithRightPrecision
						? copiedAssetWithRightPrecision?.asset1?.code === mainAsset?.code
							? copiedAssetWithRightPrecision?.asset1?.precision
							: copiedAssetWithRightPrecision?.asset2?.precision
						: asset?.precision,
					available: mainAsset?.available ?? 0,
					baseAvailable: mainAsset?.baseAvailable ?? 0,
					baseReserved: mainAsset?.baseReserved ?? 0,
				};
			}) as TExchangeItem[];

			if (selectedToAsset != undefined) {
				const tempAssetSymbols = assetSymbols?.dictionary?.filter(
					(symbol) =>
						symbol?.asset1?.code === selectedToAsset?.code ||
						symbol?.asset2?.code === selectedToAsset?.code
				);

				mappedCurrencies = mappedCurrencies
					?.filter((asset) =>
						tempAssetSymbols?.find(
							(symbol) =>
								symbol?.asset1?.code === asset?.code ||
								symbol?.asset2?.code === asset?.code
						) != undefined
							? true
							: false
					)
					?.filter((asset) => asset.code != selectedToAsset.code);
			}

			if (searchValue) {
				return mappedCurrencies.filter(
					(currency) =>
						currency.code
							.toLowerCase()
							.includes(searchValue?.toLowerCase()) ||
						currency.name.toLowerCase().includes(searchValue?.toLowerCase())
				);
			}

			return sortRests(
				mappedCurrencies as unknown as TAsset[]
			) as unknown as TExchangeItem[];
		}
	}

	const visibleAssets = useMemo(
		() => filterCurrenciesBySearchValue(marketAssets, searchValue),
		[mainCurrencies, isModalOpen, marketAssets, searchValue]
	);

	const handleModalClose = () => {
		handleIsModalOpen();
		setSearchValue('');
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleClearClick = () => {
		setSearchValue('');
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { t } = useTranslation();

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('exchange_assetToPay')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<CustomInput
							placeHolder={t('general_searchTitle') as string}
							className={'modalSelectDepositAssetSearchInput'}
							id={'exchangeSelectModalSearchInput'}
							label=""
							value={searchValue}
							isRequired={false}
							onClearClick={handleClearClick}
							type={'text'}
							onChange={handleInputChange}
							isSearch
						/>
						<h1
							onClick={() => handleItemClick(undefined)}
							className="exchangeChooseNothingText"
						>
							{t('exchange_chooseNothing')}
						</h1>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								{(mainCurrencies?.assets === undefined ||
									marketAssets === undefined) && (
									<>
										{loadingCards.map((card) => (
											<MarketAssetItem
												isLoading
												key={card}
												idx={Number(card)}
											/>
										))}
									</>
								)}
								{mainCurrencies?.assets &&
									mainCurrencies?.assets?.length > 0 &&
									marketAssets &&
									marketAssets?.length > 0 && (
										<>
											{visibleAssets?.map((asset, idx) => (
												<ExchangeFromItem
													basePrecision={
														mainCurrencies?.basePrecision
													}
													baseAsset={globalAsset.code}
													key={idx}
													idx={idx}
													asset={asset}
													handleItemClick={handleItemClick}
												/>
											))}
										</>
									)}
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const ExchangeSelectToAssetModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: ExchangeSelectAssetModalProps) => {
	const tab = useAppSelector(selectExchangeCurrentTab);
	const marketTabs = useAppSelector(selectMarketTabs);
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketAssets = useAppSelector(selectMarketAssets);
	const mainCurrencies = useAppSelector(selectMainAccountRests);

	const selectedFromAsset = useAppSelector(selectExchangeFromAsset);
	const assetSymbols = useAppSelector(selectAssetSymbolsDictionary);

	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	const [searchValue, setSearchValue] = useState<string>('');

	function filterCurrenciesBySearchValue(
		currencies: TMarketAsset[] | undefined,
		searchValue: string | undefined
	) {
		if (currencies) {
			const tempAssetSymbols = assetSymbols?.dictionary?.filter(
				(symbol) =>
					symbol?.asset1?.code === selectedFromAsset?.code ||
					symbol?.asset2?.code === selectedFromAsset?.code
			);

			let mappedAssets = currencies.map((asset) => {
				const mainAsset = mainCurrencies?.assets?.find(
					(mainAsset) => mainAsset.code === asset.code
				);
				return {
					...asset,
					available: mainAsset?.available ?? 0,
					baseAvailable: mainAsset?.baseAvailable ?? 0,
					baseReserved: mainAsset?.baseReserved ?? 0,
				};
			});

			if (selectedFromAsset != undefined) {
				mappedAssets = mappedAssets
					?.filter((asset) =>
						tempAssetSymbols?.find(
							(symbol) =>
								symbol?.asset1?.code === asset?.code ||
								symbol?.asset2?.code === asset?.code
						) != undefined
							? true
							: false
					)
					?.filter((asset) => asset.code != selectedFromAsset.code);
			}

			if (searchValue) {
				return mappedAssets.filter(
					(currency) =>
						currency.code
							.toLowerCase()
							.includes(searchValue?.toLowerCase()) ||
						currency.name.toLowerCase().includes(searchValue?.toLowerCase())
				);
			}

			return mappedAssets;
		}
	}

	const visibleAssets = useMemo(
		() =>
			filterCurrenciesBySearchValue(
				marketAssets[tab?.filter as string],
				searchValue
			),
		[isModalOpen, marketAssets, searchValue, tab]
	);

	useEffect(() => {
		if (marketTabs?.dictionary && tab === undefined) {
			dispatch(
				setExchangeCurrentTab(
					marketTabs?.dictionary?.find((tab) => tab.filter === 'all')
				)
			);
		}
	}, [isModalOpen]);

	const handleModalClose = () => {
		handleIsModalOpen();
		setSearchValue('');
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleClearClick = () => {
		setSearchValue('');
	};

	const handleTabChange = (tab: TMarketTab) => {
		dispatch(setExchangeCurrentTab(tab));
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('exchange_assetToReceive')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<CustomInput
							placeHolder={t('general_searchTitle') as string}
							className={'modalSelectDepositAssetSearchInput'}
							id={'exchangeSelectModalSearchInput'}
							label=""
							value={searchValue}
							isRequired={false}
							onClearClick={handleClearClick}
							type={'text'}
							onChange={handleInputChange}
							isSearch
						/>
						<AssetsNavigationContainer
							currentTab={tab?.name as string}
							marketTabs={marketTabs?.dictionary?.filter(
								(tab) => tab?.filter === 'all' || tab?.filter === 'crypto'
							)}
							isLoading={
								mainCurrencies?.assets === undefined ||
								marketAssets === undefined
							}
							isNoTimeFrame
							handleTabChange={handleTabChange}
						/>
						<h1
							onClick={() => handleItemClick(undefined)}
							className="exchangeChooseNothingText"
						>
							{t('exchange_chooseNothing')}
						</h1>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								{(mainCurrencies?.assets === undefined ||
									marketAssets === undefined) && (
									<>
										{loadingCards.map((card) => (
											<MarketAssetItem
												isLoading
												key={card}
												idx={Number(card)}
											/>
										))}
									</>
								)}
								{mainCurrencies?.assets &&
								mainCurrencies?.assets?.length > 0 &&
								marketAssets[tab?.filter as string] &&
								visibleAssets &&
								visibleAssets?.length > 0 ? (
									<>
										{visibleAssets?.map((asset, idx) => (
											<>
												{
													//eslint-disable-next-line
													(asset as any)?.isShowInPrices ===
														1 && (
														<MarketAssetItem<TExchangeItem>
															isDeposit
															onClick={handleItemClick}
															timeFrame={`24${t(
																'filters_h'
															)}`}
															globalAssetCode={
																globalAsset?.code
															}
															key={asset.code}
															idx={idx}
															asset={asset as TMarketAsset}
														/>
													)
												}
											</>
										))}
									</>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CryptoDepositAssetModalProps extends BaseModalProps {
	handleItemClick: (i: TInnerCryptoDepositAsset, networkCode: string) => void;
}

const CryptoDepositAssetModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: CryptoDepositAssetModalProps) => {
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.deposit?.crypto;
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketAssets = useAppSelector(selectMarketAssets)?.all;

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	const [searchValue, setSearchValue] = useState<string>('');

	const [selectAssets, setSelectAssets] = useState<TInnerCryptoDepositModified[]>([]);

	const [mutatedSelectAssets, setMutatedSelectAssets] = useState<
		TInnerCryptoDepositModified[]
	>([]);

	useEffect(() => {
		if (depositWithdrawAssetsDictionary && isModalOpen) {
			if (searchValue != '') {
				setSelectAssets(
					mutatedSelectAssets?.filter(filterCryptoAssetsBySearchValue, {
						value: searchValue,
					})
				);
			} else {
				if (depositWithdrawAssetsDictionary && marketAssets) {
					const uniqueAssets = depositWithdrawAssetsDictionary
						.map((network) => {
							return network.assets.map((asset) => {
								return {
									...asset,
									name:
										marketAssets?.find(
											(marketAsset) =>
												marketAsset?.code === asset?.code
										) != undefined
											? (marketAssets?.find(
													(marketAsset) =>
														marketAsset?.code === asset?.code
											  )?.name as string)
											: (asset?.code as string),
									networkCode: network?.networkCode,
								};
							});
						})
						.flat()
						.reduce(
							reduceCryptoAssetsToUniqueValues,
							[] as TInnerCryptoDepositModified[]
						);

					setMutatedSelectAssets(uniqueAssets);
					setSelectAssets(uniqueAssets);
				}
			}
		}
	}, [searchValue, depositWithdrawAssetsDictionary, marketAssets, isModalOpen]);

	const handleModalClose = () => {
		handleIsModalOpen();
		setSearchValue('');
		setSelectAssets([]);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleClearClick = () => {
		setSearchValue('');
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { t } = useTranslation();

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('depositCrypto_depositAsset')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<CustomInput
							placeHolder={t('general_searchTitle') as string}
							className={'modalSelectDepositAssetSearchInput'}
							id={'cryptoDepositSelectModalSearchInput'}
							label=""
							value={searchValue}
							isRequired={false}
							onClearClick={handleClearClick}
							type={'text'}
							onChange={handleInputChange}
							isSearch
						/>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								{marketAssets && marketAssets?.length > 0 ? (
									<>
										{selectAssets?.map((asset, idx) => (
											<MarketAssetItem
												isDeposit
												onClick={handleItemClick}
												timeFrame={`24${t('filters_h')}`}
												globalAssetCode={globalAsset?.code}
												key={asset.code}
												idx={idx}
												asset={marketAssets.find(
													(marketAsset) =>
														marketAsset?.code === asset?.code
												)}
												depositAsset={asset}
												networkCode={asset?.networkCode}
											/>
										))}
									</>
								) : (
									<>
										{loadingCards.map((card) => (
											<MarketAssetItem
												isLoading
												key={card}
												idx={Number(card)}
											/>
										))}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CardDepositAssetModalProps extends WalletModalProps {
	isBankDeposit?: boolean;
	currentBankCountry?: TBankCountry;
}

const CardDepositAssetModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	isBankDeposit,
	currentBankCountry,
}: CardDepositAssetModalProps) => {
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	);
	const banksDictionary = useAppSelector(selectBanksDictionary);
	const marketAssets = useAppSelector(selectMarketAssets)?.all;
	const { t } = useTranslation();

	const [selectAssets, setSelectAssets] = useState<
		TInnerBankCurrencies[] | TInnerFiatDeposit[]
	>([]);

	useEffect(() => {
		if (isModalOpen && marketAssets && depositWithdrawAssetsDictionary) {
			if (isBankDeposit) {
				setSelectAssets(
					banksDictionary?.dictionary?.deposit?.countries
						.find(
							(country) =>
								country?.countryCode === currentBankCountry?.countryCode
						)
						?.currencies.map((asset) => {
							return {
								...asset,
								name: marketAssets?.find(
									(marketAsset) => marketAsset?.code === asset?.code
								)?.name as string,
							};
						}) as TInnerBankCurrencies[]
				);
			} else {
				setSelectAssets(
					depositWithdrawAssetsDictionary?.dictionary?.deposit?.fiat[1]?.card.map(
						(asset) => {
							return {
								...asset,
								name: marketAssets?.find(
									(marketAsset) => marketAsset?.code === asset?.code
								)?.name as string,
							};
						}
					) as TInnerFiatDeposit[]
				);
			}
		}
	}, [isModalOpen, marketAssets, depositWithdrawAssetsDictionary]);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('general_chooseCurrency')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{selectAssets &&
							selectAssets?.map((accountAsset, idx) => (
								<WalletModalItem
									asset={{
										code: accountAsset.code,
										name: accountAsset?.name as string,
									}}
									key={idx}
									idx={idx}
									onClick={handleItemClick}
								/>
							))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CardWithdrawAssetModalProps extends BaseModalProps {
	isBankDeposit?: boolean;
	currentBankCountry?: TBankCountry;
	handleItemClick: (i: TInnerFiatWithdraw) => void;
}

const CardWithdrawAssetModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	isBankDeposit,
}: CardWithdrawAssetModalProps) => {
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketAssets = useAppSelector(selectMarketAssets)?.all;
	const depositWithdrawAssetsDictionary = isBankDeposit
		? useAppSelector(selectDepositWithdrawAssetsDictionary)?.dictionary?.withdraw
				?.fiat[0]?.bank
		: useAppSelector(selectDepositWithdrawAssetsDictionary)?.dictionary?.withdraw
				?.fiat[1]?.card;
	const { t } = useTranslation();

	const [selectAssets, setSelectAssets] = useState<TInnerFiatWithdrawModified[]>([]);

	const loadingCards = ['1', '2', '3', '4'];

	useEffect(() => {
		if (
			mainCurrencies?.assets &&
			marketAssets &&
			depositWithdrawAssetsDictionary &&
			isModalOpen
		) {
			setSelectAssets(
				depositWithdrawAssetsDictionary
					.map((asset) => {
						return {
							...asset,
							name: marketAssets?.find(
								(marketAsset) => marketAsset?.code === asset?.code
							)?.name as string,
							baseAvailable:
								mainCurrencies?.assets?.find(
									(mainAsset) => mainAsset?.code === asset?.code
								)?.baseAvailable ?? 0,
						};
					})
					.sort(sortAssetsByBaseAvailable)
			);
		}
	}, [mainCurrencies, depositWithdrawAssetsDictionary, marketAssets, isModalOpen]);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('general_chooseCurrency')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{selectAssets &&
						marketAssets &&
						mainCurrencies?.assets &&
						mainCurrencies ? (
							selectAssets?.map((asset, idx) => (
								<WalletItem<TInnerFiatWithdraw>
									basePrecision={mainCurrencies?.basePrecision}
									isBalanceHidden={false}
									baseAsset={globalAsset.code}
									key={idx}
									idx={idx}
									asset={
										mainCurrencies?.assets?.find(
											(mainCurrency) =>
												mainCurrency?.code === asset?.code
										)
											? mainCurrencies?.assets?.find(
													(mainCurrency) =>
														mainCurrency?.code === asset?.code
											  )
											: {
													code: asset?.code,
													name: asset?.name,
													group: 'Crypto',
													precision: 2,
													available: 0,
													reserved: 0,
													baseAvailable: 0,
													baseAvailable00h: 0,
													baseReserved: 0,
													baseReserved00h: 0,
											  }
									}
									withdrawAsset={asset}
									handleItemClick={handleItemClick}
								/>
							))
						) : (
							<>
								{loadingCards.map((card) => (
									<MarketAssetItem
										isLoading
										key={card}
										idx={Number(card)}
									/>
								))}
							</>
						)}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface PaymentSystemModalProps extends BaseModalProps {
	handleItemClick: (i: string) => void;
}

const PaymentSystemModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
}: PaymentSystemModalProps) => {
	const currentPaymentSystem = useAppSelector(selectBankWithdrawPaymentSystem);
	const selectPaymentSystem = useAppSelector(
		selectBankWithdrawIsPaymentSystemAvailable
	);

	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('withdrawBank_bic')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{selectPaymentSystem?.valueList?.length === 0 && (
							<div
								style={{
									width: '100%',
								}}
								className="walletConfirmIdentityContainer"
							>
								<WhaleIcon className="walletConfirmIdentityWhale" />
								<h1 className="walletConfirmIdentityHeader">
									{t('general_noResultsFound')}
								</h1>
							</div>
						)}
						{selectPaymentSystem?.valueList &&
							selectPaymentSystem?.valueList?.length > 0 &&
							selectPaymentSystem?.valueList?.map((paymentSystem, idx) => (
								<div
									key={idx}
									className="networkItemContainer"
									onClick={() => handleItemClick(paymentSystem)}
								>
									<div className="networkItemInnerTextContainer">
										<h1 className="networkItemInnerTextHeader">
											{paymentSystem}
										</h1>
									</div>
									{currentPaymentSystem === paymentSystem && (
										<div className="networkSelectIconContainer">
											<OperationStatusIcon status="done" />
										</div>
									)}
								</div>
							))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface BankTransferDepositBankModalProps extends BaseModalProps {
	handleItemClick: (i: TInnerBank) => void;
	banks: TBankCountry | undefined;
	currentItem: TInnerBank | undefined;
	currentAsset: TWalletModalItem | undefined;
}

const BankTransferDepositBankModal = ({
	handleIsModalOpen,
	handleItemClick,
	isModalOpen,
	banks,
	currentItem,
	currentAsset,
}: BankTransferDepositBankModalProps) => {
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const [mutatedBanks, setMutatedBanks] = useState<TInnerBank[] | undefined>([]);

	useEffect(() => {
		setMutatedBanks(
			banks?.banks?.filter((bank) =>
				banks?.currencies
					.find((currency) => currency?.code === currentAsset?.code)
					?.banks.find((currencyBankKey) => currencyBankKey === bank?.bankKey)
			)
		);
	}, [currentAsset]);

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('general_bankTransfer')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{mutatedBanks?.length === 0 ? (
							<div
								style={{
									width: '100%',
								}}
								className="walletConfirmIdentityContainer"
							>
								<WhaleIcon className="walletConfirmIdentityWhale" />
								<h1 className="walletConfirmIdentityHeader">
									{t('general_noResultsFound')}
								</h1>
							</div>
						) : (
							mutatedBanks?.map((bank, idx) => (
								<div
									key={idx}
									className="networkItemContainer"
									onClick={() => handleItemClick(bank)}
								>
									<div className="networkItemInnerTextContainer">
										<h1 className="networkItemInnerTextHeader">
											{bank?.bankName}
										</h1>
									</div>
									{currentItem?.bankName === bank?.bankName && (
										<div className="networkSelectIconContainer">
											<OperationStatusIcon status="done" />
										</div>
									)}
								</div>
							))
						)}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface BankTransferDepositCountryModalProps extends BaseModalProps {
	handleItemClick: (i: TBankCountry) => void;
}

const BankTransferDepositCountryModal = ({
	handleIsModalOpen,
	isModalOpen,
	handleItemClick,
}: BankTransferDepositCountryModalProps) => {
	const banksDictionary = useAppSelector(selectBanksDictionary);
	const countryDictionary = useAppSelector(selectCountriesDictionary);
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('savedAddresses_chooseCountry')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{banksDictionary &&
							banksDictionary?.dictionary?.deposit?.countries?.map(
								(country, idx) => (
									<div
										onClick={() => handleItemClick(country)}
										key={idx}
										className="assetItem"
									>
										<img
											className="assetIcon"
											src={
												countryDictionary?.dictionary?.find(
													(localCountry: TCountry) =>
														localCountry?.code ===
														country?.countryCode
												)?.link
											}
											alt={country?.countryCode}
										/>
										<div className="innerAssetItemHeader">
											<h1 className="innerAssetHeaderText">
												{country?.countryCode}
											</h1>
											<h1 className="innerAssetBottomText">
												{country?.countryName}
											</h1>
										</div>
									</div>
								)
							)}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface WalletModalProps extends BaseModalProps {
	handleItemClick: (i: TWalletModalItem) => void;
	currentAsset: TWalletModalItem;
}

const WalletModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	currentAsset,
}: WalletModalProps) => {
	const appSettings = useAppSelector(selectAppSettings);
	const { t } = useTranslation();

	function filterAssetsByCurrent(currentAsset: TWalletModalItem) {
		return appSettings?.accountAssets.filter(
			(asset) => asset.code != currentAsset.code
		);
	}

	const localAppSettingsItems = useMemo(
		() => filterAssetsByCurrent(currentAsset),
		[currentAsset, isModalOpen]
	);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('settings_resultingAsset')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalContent modalLocaleContent'}>
						{localAppSettingsItems &&
							localAppSettingsItems?.map((accountAsset, idx) => (
								<WalletModalItem
									asset={accountAsset}
									key={idx}
									idx={idx}
									onClick={handleItemClick}
								/>
							))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const LogoutModal = ({ handleIsModalOpen, isModalOpen }: BaseModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { logout, isPending } = useRevokeTokenMutation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleLogoutClick = () => {
		logout({
			dispatch: dispatch,
			accessToken: accessToken as string,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('settings_logOut')}?</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalLogoutOuterContainer'}>
						<h1 className="modalWalletEmptyText">
							{t('settings_logOutSubtitle')}
						</h1>
						<CustomButton
							isGrey
							text={t('settings_logOut') as string}
							type={'submit'}
							onClick={handleLogoutClick}
							isLoading={isPending}
							className={'confirmFullWidthButton'}
						/>
						<CustomButton
							text={t('general_cancel') as string}
							type={'submit'}
							onClick={handleIsModalOpen}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const CardWarningModal = ({ handleIsModalOpen, isModalOpen }: BaseModalProps) => {
	const { t } = useTranslation();
	const appSettings = useAppSelector(selectAppSettings);
	const navigate = useCustomNavigate();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('depositCard_bottomsheetInfoTitle')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalLogoutOuterContainer'}>
						<h1 className="modalWalletEmptyText">
							{appSettings?.depositWithdrawInformation?.bottomsheetText}
						</h1>
						<CustomButton
							text={t('depositCard_depositButtonText') as string}
							type={'submit'}
							onClick={() => navigate(ROUTES.DEPOSIT.CARD)}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface GenerateAccountConfirmationModalProps extends BaseModalProps {
	locale: string;
	handleLocaleModalOpen: () => void;
}

const GenerateAccountConfirmationModal = ({
	handleIsModalOpen,
	isModalOpen,
	locale,
	handleLocaleModalOpen,
}: GenerateAccountConfirmationModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);
	const isVerified = useAppSelector(selectIsVerified);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { isPending, send } = useSendGeneralAccountLetterMutation(handleModalClose);

	const handleSubmit = () => {
		send({
			accessToken: accessToken as string,
			locale: locale,
			dispatch: dispatch,
		});
	};

	if (!isVerified) {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div className={'modalWalletGlobalAssetComponent'}>
						<div className="modalComponentHeaderEndPlacement">
							<CustomButton
								isClose={true}
								onClick={handleModalClose}
								className={'registrationCloseButtonContainer'}
							>
								<CloseIcon
									sx={{
										fill: 'black',
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
						<div className={'modalGenerateAccountStatementContent'}>
							<VerificationStarIcon />
							<h1 className="modalGenerateAccountStatementUnverifiedTextHeader">
								{t('verification_bottomsheetTitle')}
							</h1>
							<h1 className="modalGenerateAccountStatementUnverifiedTextBottom">
								{t('verification_bottomsheetSubtitle')}
							</h1>
							<CustomButton
								text={t('verification_bottomsheetButton') as string}
								type={'submit'}
								onClick={() => console.log('WIP')}
								className={'confirmFullWidthButton'}
								isDisabled={false}
							/>
						</div>
					</div>
				</Fade>
			</Modal>
		);
	}

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('statement_confirmationLetter')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						className={
							'modalContent modalLocaleContent modalGenerateStatement'
						}
					>
						<div className="commonContainer">
							<div onClick={handleLocaleModalOpen} className="commonItem">
								<h1 className="commonText">{t('general_language')}</h1>
								<h1 className="innerCommonText">
									{t(
										`general_${
											locale === 'en' ? 'english' : 'russian'
										}`
									)}
								</h1>
								<RightChev />
							</div>
						</div>
						<CustomButton
							isLoading={isPending}
							text={t('statement_open') as string}
							type={'submit'}
							onClick={handleSubmit}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const BankWithdrawEditSavedAddressModal = ({
	isModalOpen,
	handleIsModalOpen,
}: BaseModalProps) => {
	const selectedBank = useAppSelector(selectBankWithdrawCurrentSavedBank);
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [templateName, setTemplateName] = useState<string>(
		selectedBank?.templateName as string
	);
	const [isTemplateNameRequired, setIsTemplateNameRequired] = useState<boolean>(false);

	useEffect(() => {
		if (selectedBank) {
			setTemplateName(selectedBank?.templateName);
			setIsTemplateNameRequired(selectedBank?.templateName?.length > 0);
		}
	}, [selectedBank]);

	const { isDeletePending, isDeleteError, deleteError, deleteBankWithdrawSavedBank } =
		useDeleteBankWithdrawSavedBank(
			dispatch,
			selectedBank?.templateId as string,
			handleIsModalOpen
		);
	const { isUpdatePending, isUpdateError, updateError, updateBankWithdrawSavedBank } =
		useUpdateBankWithdrawSavedBank(
			dispatch,
			templateName as string,
			selectedBank?.templateId as string,
			handleIsModalOpen
		);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTemplateName(e?.target?.value);
		setIsTemplateNameRequired(e?.target?.value?.length > 0);
	};

	const handleClearClick = () => {
		setTemplateName('');
		setIsTemplateNameRequired(false);
	};

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleUpdateClick = () => {
		updateBankWithdrawSavedBank({
			accessToken: accessToken as string,
			dispatch: dispatch,
			templateId: selectedBank?.templateId as string,
			templateName: templateName,
		});
	};

	const handleDeleteClick = () => {
		deleteBankWithdrawSavedBank({
			accessToken: accessToken as string,
			dispatch: dispatch,
			templateId: selectedBank?.templateId as string,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div
					className={
						'modalWalletGlobalAssetComponent modalSaveAddressComponent'
					}
				>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('savedAddresses_editSavedAddress')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div
								style={{
									gap: '24px',
								}}
								className="depositAssetSelectScrollContainer"
							>
								<CustomInput
									isRequired={isTemplateNameRequired}
									type="text"
									id="cryptoWithdrawTemplateName"
									value={templateName}
									onChange={handleInputChange}
									onClearClick={handleClearClick}
									className="confirmFullWidthButton"
									label={t('savedAddresses_addressName') as string}
								/>
								{selectedBank?.params?.map((param) => (
									<CustomInput
										isDisabled={param?.paramValue?.length > 0}
										isRequired={param?.paramValue?.length > 0}
										value={param?.paramValue}
										className="confirmFullWidthButton"
										label={param?.paramName}
										id={param?.paramName + ' ' + param?.paramValue}
										type="text"
										isNonCloseIcon
									/>
								))}
								<APIError
									isError={isDeleteError || isUpdateError}
									error={deleteError || updateError}
								/>
								<CustomButton
									isLoading={isUpdatePending}
									onClick={handleUpdateClick}
									className="confirmFullWidthButton"
									text={t('withdrawResult_saveAddress') as string}
								/>
								<CustomButton
									isLoading={isDeletePending}
									isGrey
									onClick={handleDeleteClick}
									className="confirmFullWidthButton"
									text={t('savedAddresses_removeAddress') as string}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const CryptoWithdrawEditSavedAddressModal = ({
	isModalOpen,
	handleIsModalOpen,
}: BaseModalProps) => {
	const selectedAddress = useAppSelector(selectCryptoWithdrawCurrentSavedAddress);
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [templateName, setTemplateName] = useState<string>(
		selectedAddress?.templateName as string
	);
	const [isTemplateNameRequired, setIsTemplateNameRequired] = useState<boolean>(false);

	useEffect(() => {
		if (selectedAddress) {
			setTemplateName(selectedAddress?.templateName);
			setIsTemplateNameRequired(selectedAddress?.templateName?.length > 0);
		}
	}, [selectedAddress]);

	const {
		isDeletePending,
		isDeleteError,
		deleteError,
		deleteCryptoWithdrawSavedAddresses,
	} = useDeleteCryptoWithdrawSavedAddresses(
		dispatch,
		selectedAddress?.templateId as string,
		handleIsModalOpen
	);
	const {
		isUpdatePending,
		isUpdateError,
		updateError,
		updateCryptoWithdrawSavedAddresses,
	} = useUpdateCryptoWithdrawSavedAddresses(
		dispatch,
		templateName as string,
		selectedAddress?.templateId as string,
		handleIsModalOpen
	);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTemplateName(e?.target?.value);
		setIsTemplateNameRequired(e?.target?.value?.length > 0);
	};

	const handleClearClick = () => {
		setTemplateName('');
		setIsTemplateNameRequired(false);
	};

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleUpdateClick = () => {
		updateCryptoWithdrawSavedAddresses({
			accessToken: accessToken as string,
			dispatch: dispatch,
			templateId: selectedAddress?.templateId as string,
			templateName: templateName,
			networkName: selectedAddress?.networkName as string,
		});
	};

	const handleDeleteClick = () => {
		deleteCryptoWithdrawSavedAddresses({
			accessToken: accessToken as string,
			dispatch: dispatch,
			templateId: selectedAddress?.templateId as string,
			networkName: selectedAddress?.networkName as string,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div
					className={
						'modalWalletGlobalAssetComponent modalSaveAddressComponent'
					}
				>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('savedAddresses_editSavedAddress')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div
								style={{
									gap: '24px',
								}}
								className="depositAssetSelectScrollContainer"
							>
								<CustomInput
									isRequired={isTemplateNameRequired}
									type="text"
									id="cryptoWithdrawTemplateName"
									value={templateName}
									onChange={handleInputChange}
									onClearClick={handleClearClick}
									className="confirmFullWidthButton"
									label={t('savedAddresses_addressName') as string}
								/>
								<CustomInput
									isRequired
									isSelectAsset
									isDisabled
									type="text"
									id="cryptoWithdrawSelectNetwork"
									value={selectedAddress?.networkName as string}
									imgUrl={''}
									className="confirmFullWidthButton"
									label={t('general_network') as string}
								/>
								<CustomInput
									isNonCloseIcon
									isDisabled
									label={t('withdrawCrypto_cryptoAddress') as string}
									className="confirmFullWidthButton"
									type="text"
									id="cryptoWithdrawAddress"
									isRequired={true}
									value={selectedAddress?.cryptoAddress as string}
								/>
								<APIError
									isError={isDeleteError || isUpdateError}
									error={deleteError || updateError}
								/>
								<CustomButton
									isLoading={isUpdatePending}
									onClick={handleUpdateClick}
									className="confirmFullWidthButton"
									text={t('withdrawResult_saveAddress') as string}
								/>
								<CustomButton
									isLoading={isDeletePending}
									isGrey
									onClick={handleDeleteClick}
									className="confirmFullWidthButton"
									text={t('savedAddresses_removeAddress') as string}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CryptoWithdrawSavedAddressModalProps extends BaseModalProps {
	handleItemClick: (i: string) => void;
	handleEditSavedAddressModalOpen: (i: TSavedAddress | undefined) => void;
}

const CryptoWithdrawSavedAddressModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	handleEditSavedAddressModalOpen,
}: CryptoWithdrawSavedAddressModalProps) => {
	const selectedNetwork = useAppSelector(selectCryptoWithdrawNetwork);
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isPending, getCryptoWithdrawSavedAddresses } =
		useGetCryptoWithdrawSavedAddresses(dispatch);

	useEffect(() => {
		if (isModalOpen && selectedNetwork != undefined) {
			getCryptoWithdrawSavedAddresses({
				accessToken: accessToken as string,
				dispatch: dispatch,
				networkName: selectedNetwork?.networkName?.replace(' ', '') as string,
			});
		}
	}, [isModalOpen, selectedNetwork]);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('savedAddresses_title')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								<SavedAddressItemList
									handleItemClick={handleItemClick}
									isLoading={isPending}
									handleModalOpen={handleEditSavedAddressModalOpen}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface BankWithdrawSavedBankModalModalProps extends BaseModalProps {
	handleItemClick: (i: TSavedBank) => void;
	handleEditSavedBankModalOpen: (i: TSavedBank | undefined) => void;
}

const BankWithdrawSavedBankModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	handleEditSavedBankModalOpen,
}: BankWithdrawSavedBankModalModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isPending, getBankWithdrawSavedBank } = useGetBankWithdrawSavedBank(dispatch);

	useEffect(() => {
		if (isModalOpen) {
			getBankWithdrawSavedBank({
				accessToken: accessToken as string,
				dispatch: dispatch,
			});
		}
	}, [isModalOpen]);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('savedAddresses_title')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								<SavedBanksItemList
									handleItemClick={handleItemClick}
									isLoading={isPending}
									handleModalOpen={handleEditSavedBankModalOpen}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CardWithdrawSavedCardsModalProps extends BaseModalProps {
	handleItemClick: (i: TSavedCard) => void;
	handleEditSavedCardModalOpen: (i: TSavedCard | undefined) => void;
}

const CardWithdrawSavedCardsModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	handleEditSavedCardModalOpen,
}: CardWithdrawSavedCardsModalProps) => {
	const selectedCard = useAppSelector(selectCardWithdrawCard);
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { isPending, getCardWithdrawSavedCards } =
		useGetCardWithdrawSavedCard(dispatch);

	useEffect(() => {
		if (isModalOpen) {
			getCardWithdrawSavedCards({
				accessToken: accessToken as string,
				dispatch: dispatch,
			});
		}
	}, [isModalOpen, selectedCard]);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('withdrawCard_savedCards')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								<SavedCardsItemList
									handleItemClick={handleItemClick}
									isLoading={isPending}
									handleModalOpen={handleEditSavedCardModalOpen}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const CardWithdrawEditSavedAddressModal = ({
	isModalOpen,
	handleIsModalOpen,
}: BaseModalProps) => {
	const selectedCard = useAppSelector(selectCardWithdrawCard);
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [templateName, setTemplateName] = useState<string>(
		selectedCard?.templateName as string
	);
	const [isTemplateNameRequired, setIsTemplateNameRequired] = useState<boolean>(false);

	useEffect(() => {
		if (selectedCard) {
			setTemplateName(selectedCard?.templateName);
			setIsTemplateNameRequired(selectedCard?.templateName?.length > 0);
		}
	}, [selectedCard]);

	const { isDeletePending, isDeleteError, deleteError, deleteCardWithdrawSavedCard } =
		useDeleteCardWithdrawSavedCard(
			dispatch,
			selectedCard?.templateId as string,
			handleIsModalOpen
		);
	const { isUpdatePending, isUpdateError, updateError, updateCardWithdrawSavedCard } =
		useUpdateCardWithdrawSavedCard(
			dispatch,
			templateName as string,
			selectedCard?.templateId as string,
			handleIsModalOpen
		);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTemplateName(e?.target?.value);
		setIsTemplateNameRequired(e?.target?.value?.length > 0);
	};

	const handleClearClick = () => {
		setTemplateName('');
		setIsTemplateNameRequired(false);
	};

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleUpdateClick = () => {
		updateCardWithdrawSavedCard({
			accessToken: accessToken as string,
			dispatch: dispatch,
			templateId: selectedCard?.templateId as string,
			templateName: templateName,
		});
	};

	const handleDeleteClick = () => {
		deleteCardWithdrawSavedCard({
			accessToken: accessToken as string,
			dispatch: dispatch,
			templateId: selectedCard?.templateId as string,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div
					className={
						'modalWalletGlobalAssetComponent modalSaveAddressComponent'
					}
				>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('savedAddresses_editSavedCard')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div
								style={{
									gap: '24px',
								}}
								className="depositAssetSelectScrollContainer"
							>
								<CustomInput
									isRequired={isTemplateNameRequired}
									type="text"
									id="cryptoWithdrawTemplateName"
									value={templateName}
									onChange={handleInputChange}
									onClearClick={handleClearClick}
									className="confirmFullWidthButton"
									label={t('savedAddresses_cardName') as string}
								/>
								<CustomInput
									isNonCloseIcon
									isDisabled
									label={t('general_cardNumber') as string}
									className="confirmFullWidthButton"
									type="text"
									id="cryptoWithdrawAddress"
									isRequired={true}
									value={selectedCard?.cardNumber as string}
								/>
								<APIError
									isError={isDeleteError || isUpdateError}
									error={deleteError || updateError}
								/>
								<CustomButton
									isLoading={isUpdatePending}
									onClick={handleUpdateClick}
									className="confirmFullWidthButton"
									text={t('withdrawResult_saveAddress') as string}
								/>
								<CustomButton
									isLoading={isDeletePending}
									isGrey
									onClick={handleDeleteClick}
									className="confirmFullWidthButton"
									text={t('savedAddresses_remove') as string}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface CryptoWithdrawHistoryModalProps extends BaseModalProps {
	handleItemClick: (i: TOperation) => void;
}

const CryptoWithdrawHistoryModal = ({
	handleIsModalOpen,
	handleItemClick,
	isModalOpen,
}: CryptoWithdrawHistoryModalProps) => {
	const { t } = useTranslation();

	const { isLoading, getOperationsByTabChange } = useGetDefaultHistory();

	useEffect(() => {
		if (isModalOpen) {
			getOperationsByTabChange('withdraw');
		}
	}, [isModalOpen]);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('withdrawCrypto_withdrawHistory')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div className="depositAssetSelectScrollContainer">
								<HistoryItemList
									handleItemClick={handleItemClick}
									isLoading={isLoading}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface HistoryOperationDetailsModalProps extends BaseModalProps {
	handleRejectOperation: (operId?: string) => void;
}

const HistoryOperationDetailsModal = ({
	handleIsModalOpen,
	isModalOpen,
	handleRejectOperation,
}: HistoryOperationDetailsModalProps) => {
	const dispatch = useAppDispatch();
	const operation = useAppSelector(selectCurrentOperation);
	const operationDetails = useAppSelector(selectOperationDetails);

	const { t } = useTranslation();

	const handleModalClose = () => {
		dispatch(setOperationDetails(undefined));
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleCopyClick = async (value: string) => {
		await navigator.clipboard.writeText(value);
	}

	const loadingCards = ['1', '2', '3', '4'];

	if (!operationDetails) {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div className={'modalOperationDetailsComponentGrey'}>
						<div className="modalComponentHeader">
							<h1 className={'confirmEmailTextLoading'} />
							<CustomButton
								isClose={true}
								onClick={handleModalClose}
								className={'statementModalGreyContainer'}
							>
								<CloseIcon
									sx={{
										fill: 'black',
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
						<div className={'modalOperationDetailsContent'}>
							<div className="commonOperationDetailsContainer">
								<div className="commonOperationDetailsItem">
									<h1 className="innerCommonTextLoading" />
									<h1 className="commonTextLoading" />
								</div>
							</div>

							<div className="commonOperationDetailsContainer">
								{loadingCards?.map((card) => (
									<div
										key={card}
										className="commonOperationDetailsItem"
									>
										<h1 className="innerCommonTextLoading" />
										<h1 className="commonTextLoading" />
									</div>
								))}
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		);
	}

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalOperationDetailsComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{operationDetails?.operationType}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalOperationDetailsContent'}>
						<div className="commonOperationDetailsContainer">
							<div className="commonOperationDetailsItem">
								<h1 className="keyOperationDetailsText">
									{operationDetails?.status?.paramName}
								</h1>
								<h1 className="valueOperationDetailsText">
									<OperationStatusIcon
										status={
											operationDetails?.status
												?.paramStatus as string
										}
										className="operationDetailsIcon"
									/>
									{operationDetails?.status?.paramValue}
								</h1>
							</div>
						</div>
						{operationDetails?.blocks?.map((block) => (
							<div className="commonOperationDetailsContainer">
								{block?.block?.map((innerBlock) => (
									<>
										<div className="commonOperationDetailsItem">
											<h1 className="keyOperationDetailsText">
												{innerBlock?.paramName}
											</h1>
											<h1 className="valueOperationDetailsText">
												{innerBlock?.paramValue}
											</h1>
										</div>
										{innerBlock?.paramCopy && (
											<div onClick={() => handleCopyClick(innerBlock?.paramValue)} className="copyGreenContainer">
												<CopyGreenIcon className="copyGreenIcon" />
												<h1 className="copyGreenText">
													{innerBlock?.paramCopy}
												</h1>
											</div>
										)}
									</>
								))}
							</div>
						))}
					</div>
					{operationDetails?.isCancelable === 1 && (
						<CustomButton
							onClick={() => handleRejectOperation(operation?.operaionid)}
							className="confirmFullWidthButton"
							isRedText
							text={t('operation_cancelOperationTitle') as string}
						/>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

interface BankDepositInvoiceHistoryModalProps extends BaseModalProps {
	isLoading: boolean;
}

const BankDepositInvoiceHistoryModal = ({
	isLoading,
	isModalOpen,
	handleIsModalOpen,
}: BankDepositInvoiceHistoryModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);
	const invoiceId = useAppSelector(selectCurrentInvoiceId);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const { deleteBankDepositInvoice, isError, error, isPending } =
		useDeleteBankDepositInvoice(dispatch, handleModalClose);

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleDeleteInvoice = () => {
		deleteBankDepositInvoice({
			accessToken: accessToken as string,
			dispatch: dispatch,
			invoiceId: invoiceId,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('invoice_title')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						className={
							'modalContent modalLocaleContent modalGenerateStatement'
						}
					>
						<div
							style={{
								width: '100%',
							}}
							className="modalAssetDepositSelectContent"
						>
							<div
								style={{
									gap: '16px',
								}}
								className="depositAssetSelectScrollContainer"
							>
								<BankTransferInvoicePreviewLayout isLoading={isLoading} />
								<APIError isError={isError} error={error} />
								<CustomButton
									isDisabled={isLoading}
									isLoading={isPending}
									isGrey
									text={t('invoice_delete') as string}
									className="confirmFullWidthButton"
									onClick={handleDeleteInvoice}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const BankTransferDepositDetailsModal = ({
	handleIsModalOpen,
	isModalOpen,
}: BaseModalProps) => {
	const invoiceInfo = useAppSelector(selectInvoiceInfo);

	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleCopyClick = (copyValue: string) => {
		navigator.clipboard.writeText(copyValue);
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('invoice_bankCredentials')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={
							'modalContent modalLocaleContent modalGenerateStatement'
						}
					>
						<div
							style={{
								width: '100%',
							}}
							className="modalAssetDepositSelectContent"
						>
							<div
								style={{
									gap: '16px',
								}}
								className="depositAssetSelectScrollContainer"
							>
								{invoiceInfo?.map((invoice) => (
									<>
										{invoice?.isVisible && (
											<div className="commonContainer">
												<div className="invoiceDetailsContainer">
													<h1 className="invoiceDetailsHeaderText">
														{invoice?.paramName}
													</h1>
													<div className="invoiceDetailsInnerContainer">
														<h1 className="invoiceDetailsBottomText">
															{invoice?.paramValue}
														</h1>
														<CopyIcon
															className=""
															onClick={() =>
																handleCopyClick(
																	invoice?.paramValue
																)
															}
														/>
													</div>
												</div>
											</div>
										)}
									</>
								))}
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface HistoryPeriodModalProps extends BaseModalProps {
	handleCustomPeriodModalOpen: () => void;
	handleItemClick: (i: THistoryPeriod) => void;
	handleResetClick: () => void;
	currentTimeFrame: THistoryPeriod | undefined;
}

const HistoryPeriodModal = ({
	isModalOpen,
	handleCustomPeriodModalOpen,
	handleIsModalOpen,
	handleItemClick,
	handleResetClick,
	currentTimeFrame,
}: HistoryPeriodModalProps) => {
	const { t } = useTranslation();
	const historyPeriods = useAppSelector(selectPeriodFiltersDictionary).dictionary
		?.historyperiod;

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('history_period')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						className={
							'modalContent modalLocaleContent modalGenerateStatement'
						}
					>
						<div className="commonContainer">
							{historyPeriods?.map((period, idx) => (
								<HistoryPeriodModalItem
									item={period}
									handleItemClick={handleItemClick}
									idx={idx}
									isActive={
										period?.datefilter ===
										currentTimeFrame?.datefilter
									}
								/>
							))}
							<HistoryPeriodModalItem
								idx={(historyPeriods?.length as number) + 1}
								isCustom
								name={t('calendar_customPeriod') as string}
								handleCustomPeriodModalOpen={handleCustomPeriodModalOpen}
								datePeriod={
									currentTimeFrame?.datefilter === 'period'
										? currentTimeFrame.shortName
										: ''
								}
							/>
						</div>
						<CustomButton
							isGrey
							isLoading={false}
							text={t('history_reset') as string}
							type={'submit'}
							onClick={handleResetClick}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface SaveAddressModalProps extends BaseModalProps {
	type: TSaveAddressSelectType;
	setIsNeedToSave?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaveAddressModal = ({
	type,
	isModalOpen,
	handleIsModalOpen,
	setIsNeedToSave,
}: SaveAddressModalProps) => {
	const { t } = useTranslation();

	const handleModalClose = () => {
		if (setIsNeedToSave) {
			setIsNeedToSave(false);
		}
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	if (type === 'crypto') {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div
						className={
							'modalWalletGlobalAssetComponent modalSaveAddressComponent'
						}
					>
						<div className="modalComponentHeaderEndPlacement">
							<h1 className={'confirmEmailText'}>
								{t('savedAddresses_addAddress')}
							</h1>
							<CustomButton
								isClose={true}
								onClick={handleModalClose}
								className={'registrationCloseButtonContainer'}
							>
								<CloseIcon
									sx={{
										fill: 'black',
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
						<SavedAddressesTabModalContainer
							tab="crypto"
							handleModalClose={handleModalClose}
						/>
					</div>
				</Fade>
			</Modal>
		);
	}

	if (type === 'card') {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div
						className={
							'modalWalletGlobalAssetComponent modalSaveAddressComponent'
						}
					>
						<div className="modalComponentHeaderEndPlacement">
							<h1 className={'confirmEmailText'}>
								{t('savedAddresses_saveCard')}
							</h1>
							<CustomButton
								isClose={true}
								onClick={handleModalClose}
								className={'registrationCloseButtonContainer'}
							>
								<CloseIcon
									sx={{
										fill: 'black',
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
						<SavedAddressesTabModalContainer
							tab="card"
							handleModalClose={handleModalClose}
						/>
					</div>
				</Fade>
			</Modal>
		);
	}

	if (type === 'bank') {
		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div
						className={
							'modalWalletGlobalAssetComponent modalSaveAddressComponent'
						}
					>
						<div className="modalComponentHeaderEndPlacement">
							<h1 className={'confirmEmailText'}>
								{t('savedAddresses_addAddress')}
							</h1>
							<CustomButton
								isClose={true}
								onClick={handleModalClose}
								className={'registrationCloseButtonContainer'}
							>
								<CloseIcon
									sx={{
										fill: 'black',
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
						<SavedAddressesTabModalContainer
							tab="bank"
							handleModalClose={handleModalClose}
						/>
					</div>
				</Fade>
			</Modal>
		);
	}

	if (type === 'common') {
		const [tab, setTab] = useState<TSaveAddressTabs>('crypto');

		const handleTabClick = (tempTab: TSaveAddressTabs) => {
			setTab(tempTab);
		};

		return (
			<Modal
				sx={{
					position: 'absolute',
					zIndex: 1300,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-end',
					inset: 0,
				}}
				open={isModalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				disableAutoFocus={true}
				onKeyDown={handleKeyPress}
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={isModalOpen} timeout={200}>
					<div
						className={
							'modalWalletGlobalAssetComponent modalSaveAddressComponent'
						}
					>
						<div className="modalComponentHeaderEndPlacement">
							<h1 className={'confirmEmailText'}>
								{t('savedAddresses_addNewAddress')}
							</h1>
							<CustomButton
								isClose={true}
								onClick={handleModalClose}
								className={'registrationCloseButtonContainer'}
							>
								<CloseIcon
									sx={{
										fill: 'black',
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
						<SaveAddressTabContainer tab={tab} onClick={handleTabClick} />
						<SavedAddressesTabModalContainer
							isCommon
							tab={tab}
							handleModalClose={handleIsModalOpen}
						/>
					</div>
				</Fade>
			</Modal>
		);
	}

	return <></>;
};

interface RejectOperationModalProps extends BaseModalProps {
	operId: string;
	additionalFunc?: () => void;
}

const RejectOperationModal = ({
	handleIsModalOpen,
	isModalOpen,
	operId,
	additionalFunc,
}: RejectOperationModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const { isPending, reject, isError, error } = useRejectOperation(
		handleModalClose,
		additionalFunc
	);

	const handleSubmit = () => {
		reject({
			accessToken: accessToken as string,
			operId: operId,
			dispatch: dispatch,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeaderEndPlacement">
						<h1 className={'confirmEmailText'}>
							{t('operation_cancelOperationTitle')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalGenerateAccountStatementContent'}>
						<h1 className="rejectOperationModalSubtitleText">
							{t('operation_cancelOperationSubtitle')}
						</h1>
						<APIError isError={isError} error={error} />
						<CustomButton
							text={t('operation_confirmCancellationTitle') as string}
							type={'submit'}
							isLoading={isPending}
							isGrey
							onClick={handleSubmit}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
						<CustomButton
							text={t('operation_dismissCancellationTitle') as string}
							type={'submit'}
							onClick={handleIsModalOpen}
							className={'confirmFullWidthButton'}
							isDisabled={false}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface GenerateStatementModalModalProps extends BaseModalProps {
	locale: string;
	handleLocaleModalOpen: () => void;
}

const GenerateStatementModal = ({
	handleIsModalOpen,
	isModalOpen,
	locale,
	handleLocaleModalOpen,
}: GenerateStatementModalModalProps) => {
	const accessToken = useAppSelector(selectAccessToken);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const [historyPeriod, setHistoryPeriod] = useState<THistoryPeriod | undefined>();
	const [datePeriod, setDatePeriod] = useState<THistoryDatePeriod | undefined>();
	const [dateNamePeriod, setDateNamePeriod] = useState<string>('');

	const historyPeriods = useAppSelector(selectPeriodFiltersDictionary).dictionary
		?.historyperiod;

	const [isHistoryCustomPeriodModalOpen, setIsHistoryCustomPeriodModalOpen] =
		useState<boolean>(false);

	const handleModalClose = () => {
		setDatePeriod(undefined);
		setDateNamePeriod('');
		setHistoryPeriod(undefined);
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleCustomPeriodModalOpen = () => {
		setIsHistoryCustomPeriodModalOpen(!isHistoryCustomPeriodModalOpen);
	};

	const handlePeriodItemClick = (i: THistoryPeriod) => {
		setHistoryPeriod(i);
		setDatePeriod(undefined);
		setDateNamePeriod('');
	};

	const { isPending, send } = useSendStatementMutation(handleModalClose);

	const handleSubmit = () => {
		send({
			accessToken: accessToken as string,
			locale: locale,
			dispatch: dispatch,
			period: historyPeriod ? (historyPeriod?.datefilter as string) : undefined,
			datePeriod: datePeriod ? datePeriod : undefined,
		});
	};

	const handleCustomPeriodSubmit = (e: THistoryDatePeriodWithDate) => {
		setHistoryPeriod(undefined);
		const datePeriod = {
			dateFrom: e.dateFrom?.format('DD/MM/YYYY').replaceAll('/', '') as string,
			dateTo: e.dateTo?.format('DD/MM/YYYY').replaceAll('/', '') as string,
			fullDateFrom: e.fullDateFrom,
			fullDateTo: e.fullDateTo,
		};
		setDatePeriod(datePeriod);
		setDateNamePeriod(
			e.dateFrom?.format('DD/MM').replaceAll('/', '.') +
				' - ' +
				e.dateTo?.format('DD/MM').replaceAll('/', '.')
		);
		handleCustomPeriodModalOpen();
	};

	if (isHistoryCustomPeriodModalOpen) {
		return (
			<HistoryCustomPeriodModal
				handleIsModalOpen={handleCustomPeriodModalOpen}
				isModalOpen={isHistoryCustomPeriodModalOpen}
				handleSubmit={handleCustomPeriodSubmit}
			/>
		);
	}

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalComponentGrey'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>{t('statement_title')}</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'statementModalGreyContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalLocaleContent modalGenerateStatement'}>
						<div className="commonContainer">
							<div onClick={handleLocaleModalOpen} className="commonItem">
								<h1 className="commonText">{t('general_language')}</h1>
								<h1 className="innerCommonText">
									{t(
										`general_${
											locale === 'en' ? 'english' : 'russian'
										}`
									)}
								</h1>
								<RightChev />
							</div>
						</div>

						<div className="commonContainer">
							{historyPeriods?.map((period, idx) => (
								<HistoryPeriodModalItem
									isActive={
										period.datefilter === historyPeriod?.datefilter
									}
									item={period}
									handleItemClick={handlePeriodItemClick}
									idx={idx}
								/>
							))}
							<HistoryPeriodModalItem
								idx={(historyPeriods?.length as number) + 1}
								isCustom
								name={t('calendar_customPeriod') as string}
								handleCustomPeriodModalOpen={handleCustomPeriodModalOpen}
								datePeriod={dateNamePeriod != '' ? dateNamePeriod : ''}
							/>
						</div>
						<CustomButton
							isLoading={isPending}
							text={t('statement_open') as string}
							type={'submit'}
							onClick={handleSubmit}
							className={'confirmFullWidthButton'}
							isDisabled={
								historyPeriod === undefined && datePeriod === undefined
							}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

interface HistoryCustomPeriodModalProps extends BaseModalProps {
	handleSubmit: (e: THistoryDatePeriodWithDate) => void;
}

const HistoryCustomPeriodModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleSubmit,
}: HistoryCustomPeriodModalProps) => {
	const locale = useAppSelector(selectLocale);
	const historyDatePeriod = useAppSelector(selectDatePeriod);
	const { t } = useTranslation();

	const [dateFrom, setDateFrom] = useState<Dayjs | null>(
		historyDatePeriod?.fullDateFrom != ''
			? dayjs(historyDatePeriod?.fullDateFrom)
			: null
	);
	const [dateTo, setDateTo] = useState<Dayjs | null>(
		historyDatePeriod?.fullDateTo != '' ? dayjs(historyDatePeriod?.fullDateTo) : null
	);
	const [isError, setIsError] = useState<boolean>(false);

	const handleModalClose = () => {
		setDateFrom(null);
		setDateTo(null);
		setIsError(false);
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	const handleSubmitClick = () => {
		setDateFrom(null);
		setDateTo(null);
		setIsError(false);

		const datePeriod = {
			dateFrom: dateFrom as Dayjs,
			dateTo: dateTo as Dayjs,
			fullDateFrom: dateFrom?.toISOString() as string,
			fullDateTo: dateTo?.toISOString() as string,
		};

		handleSubmit(datePeriod);
	};

	const handleDateFromChange = (e: Dayjs | null) => {
		setIsError(false);
		setDateFrom(e);
	};

	const handleDateToChange = (e: Dayjs | null) => {
		setIsError(false);
		setDateTo(e);
	};

	useEffect(() => {
		if (dateFrom && dateTo) {
			setIsError(dateTo.isBefore(dateFrom));
		}
	}, [dateFrom, dateTo]);

	const handleTodayClick = () => {
		setDateTo(dayjs().locale(locale));
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div className={'modalWalletGlobalAssetComponent'}>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('calendar_customPeriod')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div className={'modalLocaleContent modalGenerateStatement'}>
						<CustomDatePicker
							value={dateFrom}
							isFrom
							onChange={handleDateFromChange}
							label={t('calendar_dateFrom')}
						/>

						<CustomDatePicker
							value={dateTo}
							onChange={handleDateToChange}
							label={t('calendar_dateTo')}
							handleTodayClick={handleTodayClick}
						/>

						<APIError
							isError={isError}
							error={{ message: t('calendar_dateToError') }}
						/>

						<CustomButton
							text={t('calendar_apply') as string}
							type={'submit'}
							onClick={handleSubmitClick}
							className={'confirmFullWidthButton'}
							isDisabled={dateFrom === null || dateTo === null || isError}
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

const DisableTwoFaConfirmationModal = ({
	isModalOpen,
	handleIsModalOpen,
}: BaseModalProps) => {
	const currentTwoFactorOption = useAppSelector(selectTwoFaOptionsCurrentOption);
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const { disableTwoFaMutation, isDisableError, isDisablePending, disableError } =
		useDisableTwoFaMutation(navigate);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleCloseClick();
		}
	};

	const handleCloseClick = () => {
		dispatch(
			updateTwoFactorOption({
				type: currentTwoFactorOption?.identityTypeName,
				value: !currentTwoFactorOption?.isEnabled,
			})
		);
		handleModalClose();
	};

	const handleDisableClick = () => {
		disableTwoFaMutation({
			accessToken: accessToken as string,
			dispatch: dispatch,
			identityType: currentTwoFactorOption?.identityTypeName as string,
			navigate: navigate,
		});
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				inset: 0,
			}}
			open={isModalOpen}
			onClose={handleCloseClick}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div
					className={
						'modalWalletGlobalAssetComponent modalSaveAddressComponent'
					}
				>
					<div className="modalComponentHeader">
						<h1 className={'confirmEmailText'}>
							{t('twoFactorAuthSettings_disable2FA')}
						</h1>
						<CustomButton
							isClose={true}
							onClick={handleModalClose}
							className={'registrationCloseButtonContainer'}
						>
							<CloseIcon
								sx={{
									fill: 'black',
								}}
								className={'registrationCloseButton'}
							/>
						</CustomButton>
					</div>
					<div
						style={{
							overflowY: 'scroll',
						}}
						className={'modalContent modalLocaleContent'}
					>
						<div className="modalAssetDepositSelectContent">
							<div
								style={{
									gap: '24px',
								}}
								className="depositAssetSelectScrollContainer"
							>
								<h1 className="modalWalletEmptyText">
									{t('twoFactorAuthSettings_disable2FAWarn')}
								</h1>
								<APIError isError={isDisableError} error={disableError} />
								<CustomButton
									isLoading={isDisablePending}
									isGrey
									onClick={handleDisableClick}
									className="confirmFullWidthButton"
									text={t('quickLogin_disable') as string}
								/>
								<CustomButton
									onClick={handleCloseClick}
									className="confirmFullWidthButton"
									text={t('general_cancel') as string}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export {
	DisableTwoFaConfirmationModal,
	SaveAddressModal,
	CardWithdrawAssetModal,
	CryptoWithdrawEditSavedAddressModal,
	CryptoWithdrawSavedAddressModal,
	CryptoWithdrawHistoryModal,
	BankTransferDepositBankModal,
	BankTransferDepositCountryModal,
	CountryModal,
	OTPModal,
	ConfirmRegistrationModal,
	LocaleModal,
	WalletModal,
	WalletConvertModal,
	WalletTransferRestsToMainModal,
	MarketTimeFrameModal,
	ThemeModal,
	LogoutModal,
	GenerateAccountConfirmationModal,
	HistoryPeriodModal,
	AssetDetailsTransferModal,
	HistoryOperationDetailsModal,
	RejectOperationModal,
	GenerateStatementModal,
	CardWarningModal,
	HistoryCustomPeriodModal,
	CardDepositAssetModal,
	CryptoDepositAssetModal,
	CryptoDepositNetworkModal,
	BankTransferDepositDetailsModal,
	BankDepositInvoiceHistoryModal,
	CryptoWithdrawAssetModal,
	CardWithdrawSavedCardsModal,
	BankWithdrawEditSavedAddressModal,
	CardWithdrawEditSavedAddressModal,
	BankWithdrawSavedBankModal,
	ExchangeSelectFromAssetModal,
	ExchangeSelectToAssetModal,
	PaymentSystemModal,
};
